import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
    me: {
        id: '',
        displayName: '',
        title: '',
        displayNameSet: false, // true if got from cookie or manually set.
        device: { flag: '', name: '', version: '' },
        canSendMic: true,
        canSendWebcam: true,
        canChangeWebcam: false,
        webcamInProgress: false,
        shareInProgress: false,
        audioOnly: false,
        audioOnlyInProgress: false,
        audioMuted: false,
        restartIceInProgress: false,
    },
    children: null,
    peers: [],
    producers: [],
    dataProducers: [],
    dataConsumers: [],
    consumers: [],
    room: {
        url: '',
        state: '', // new/connecting/connected/closed
        activeSpeakerId: '',
        statsPeerId: null,
        faceDetection: false
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}