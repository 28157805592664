<template>
  <el-container class="layout-container">
    <el-aside>
      <div class="logo">
        <img src="../assets/img/logo.png" class="logo" v-if="isCollapse == false" />
        <img src="../assets/img/Compact.png" class="mobile" v-else />
      </div>
      <el-row class="tac">
        <el-col :span="24">
          <el-menu :default-active="activeMenu" class="el-menu-vertical-demo side-menu" :collapse="isCollapse" :router="true">
            <el-menu-item index="/doktor">
              <i class="menu-icon-dashboard"></i>
              <span slot="title">{{ $localization('web-doctorlayout-main-page') }}</span>
            </el-menu-item>
            <!-- <el-menu-item index="/doktor/calendar">
              <i class="menu-icon-calendar"></i>
              <span>Takvim</span>
            </el-menu-item> -->
            <el-menu-item index="/doktor/mesajlar">
              <i class="menu-icon-messages"></i>
              <span slot="title" style="display: flex; gap: 10px">{{ $localization('web-doctorlayout-messages') }} <span style="color: red"
                  v-if="user.UnreadMessagesCount != 0">({{ user.UnreadMessagesCount }})</span>
              </span>
            </el-menu-item>
            <el-menu-item index="/doktor/dosyalar">
              <i class="menu-icon-folder"></i>
              <span slot="title">{{ $localization('web-doctorlayout-files') }}</span>
            </el-menu-item>
            <el-menu-item index="/doktor/profilim">
              <i class="menu-icon-user"></i>
              <span slot="title">{{ $localization('web-doctorlayout-profile') }}</span>
            </el-menu-item>
          </el-menu>
        </el-col>
      </el-row>
    </el-aside>
    <el-container>
      <el-header>
        <div @click="isCollapse = !isCollapse" class="menu-collapse"></div>
        <div class="right">
          <div class="buttons-group">
            <!-- <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                <div class="menu-icon-language"></div>
                <i class="el-icon-arrow-down"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>TR</el-dropdown-item>
                <el-dropdown-item>EN</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
            <!-- <div class="divider"></div> -->
            <el-popover placement="bottom" width="300" popper-class="notifi-pop" trigger="click">
              <div class="notification-container">
                <div class="top">
                  <div class="left">
                    {{ $localization('web-doctorlayout-notifications') }}
                    <span v-if="$store.state.auth.notifyCount > 0">{{ $store.state.auth.notifyCount }}</span>
                  </div>
                  <div style="cursor: pointer" class="right" @click="deleteAllNotification()">{{
                    $localization('web-doctorlayout-clear-all') }}</div>

                  <!-- <div class="right">Tümünü Gör</div> -->
                </div>
                <ul class="middle infinite-list" v-infinite-scroll="load">
                  <li class="infinite-list-item" v-for="item in notifyList" :key="item.ID">
                    <div class="left near">
                      <div class="icon"></div>
                    </div>
                    <div class="right">
                      <div class="title">{{ item.Title }}</div>
                      <div class="time">
                        {{ $moment(item.CreatedDateTime).fromNow() }}
                      </div>
                    </div>
                    <div class="close">
                      <div @click="deleteNotification(item.ID, index)" class="icon"></div>
                    </div>
                  </li>
                </ul>
                <div class="bottom">
                  <div class="bot2" @click="markAllAsRead">
                    <div>{{ $localization('web-doctorlayout-mark-read-all') }}</div>
                    <i class="icon-arrow"> </i>
                  </div>
                </div>
              </div>
              <el-button slot="reference" class="notification-button" v-if="user.UnreadNotificationCount > 0" @click="notifySet()" circle>
                <img src="@/assets/img/icons/bell-notification.svg" alt="" />
              </el-button>
              <el-button slot="reference" class="notification-button" v-else icon="menu-icon-bell" @click="notifySet()" circle></el-button>
            </el-popover>
            <el-select v-model="language" style="width: 150px" @change="setLanguage(language)">
              <el-option v-for="item in languageList" :key="item.ID" :label="item.Title" :value="item.LanguageCode"
                style="display: flex; align-items: center">
                <img :src="item.Image" style="height: 20px; width: 30px; margin-right: 10px" />
                {{ item.Title }}
              </el-option>
            </el-select>
            <div class="divider"></div>
            <div class="user-info">
              <div class="user-avatar">
                <div class="short">{{ user.FirstName[0] }}{{ user.LastName[0] }}</div>
              </div>
              <div class="user-name">
                <span>{{ user.FirstName }} {{ user.LastName }}</span>
              </div>
            </div>
          </div>
          <div class="menu-icon-logout" @click="logout()"></div>
          <audio src="../assets/sounds/Notification.mp3" playsinline ref="appointmentAlarm" style="display: none"></audio>
          <audio src="../assets/sounds/alarm1.wav" playsinline ref="alarm" style="display: none"></audio>
        </div>
      </el-header>
      <portal-target name="new-header"> </portal-target>
      <el-main>
        <vue-page-transition name="fade-in-up">
          <!-- HS 23/05/2023 alarm fonksiyonu alt vue nesnelerine pass edildi. -->
          <router-view @stopAlarm="stopAlarm"></router-view>
        </vue-page-transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      languageList: [],
      language: JSON.parse(JSON.stringify(this.$store.state.auth.language)),
      activeMenu: this.$route.path,
      isCollapse: false,
      // HS 20/05/2023 !!! alarm intervali eklendi
      //alarmInterval: setInterval,
    };
  },
  beforeDestroy() {
    this.$socket.off();
    this.$socket.disconnect();
  },

  async beforeMount() {
    await this.getLanguageList();
    this.$socket = this.$io(process.env.VUE_APP_SOCKETURL, {
      transports: ["websocket"],
      upgrade: false,
      query: {
        token: localStorage.getItem("token"),
      },
    });
    this.$store.commit("setSocket", this.$socket);
    this.$socket.off("reconnect_attempt");
    this.$socket.on("reconnect_attempt", () => {
      console.log("reconnect_attempt");
    });
    this.$socket.off("disconnect");
    this.$socket.on("disconnect", () => {
      console.log("disconnect");
    });

    this.$socket.off("subscribe_success");
    this.$socket.on("subscribe_success", (room) => {
      if (room == "presence-user-" + this.user.ID) {
        this.$socket.off("presence-user-" + this.user.ID + "-pendingAppointments");
        this.$socket.on("presence-user-" + this.user.ID + "-pendingAppointments", (data2) => {
          //HS 23/05/2023 !!! (&& this.$route.name != "DoktorHome")   ana ekranda da ses çalması içn kaldırıldı.
          if (this.$store.state.appointments.pendingAppointments.length < data2.data.Appointments.length && this.$route.name != "DoktorGorusmeOdasi") {
            //this.$refs.appointmentAlarm.play(0);
            this.$message.info("Yeni bir görüşme var");
          }
          this.$store.commit("setPendingAppointments", data2.data.Appointments);

          //#region HS 23/05/2023 sürekli çalan zil sesi eklendi
          this.playStopAlarm();
          //#endregion

        });
        this.$socket.off("presence-user-" + this.user.ID + "-newAppointmentMessage");
        this.$socket.on("presence-user-" + this.user.ID + "-newAppointmentMessage", (messages) => {
          if (messages.data.Message.SenderUserId != this.user.ID) {
            this.$refs.appointmentAlarm.play(0);
          }
          this.$store.commit("addCurrentAppointmentMessage", messages.data.Message);
          this.$nextTick(() => {
            this.jQuery("#scroll-list").animate({ scrollTop: this.jQuery("#scroll-list").prop("scrollHeight") }, 250);
          });
        });
        this.$socket.off("presence-user-" + this.user.ID + "-newMessage");
        this.$socket.on("presence-user-" + this.user.ID + "-newMessage", () => {
          this.user.UnreadMessagesCount += 1;
        });
        this.$socket.off("presence-user-" + this.user.ID + "-youJoinedAnAppointmentFromAnotherWindowNowCloseThisOne");
        this.$socket.on("presence-user-" + this.user.ID + "-youJoinedAnAppointmentFromAnotherWindowNowCloseThisOne", () => {
          if (window.doNotThrowMe != true) {
            this.$router.push("/");
          }
        });
        this.$socket.off("presence-user-" + this.user.ID + "-removePendingAppointment");
        this.$socket.on("presence-user-" + this.user.ID + "-removePendingAppointment", (data2) => {
          this.$store.commit("deletePendingAppointments", data2.data.ApponitmentID);
        });
        this.$socket.off("presence-user-" + this.user.ID + "-forceSignOut");
        this.$socket.on("presence-user-" + this.user.ID + "-forceSignOut", () => {
          localStorage.removeItem("token");
          this.$store.state.auth.user = null;
          this.$router.push("/");
        });
      }
    });
    this.$socket.emit("subscribe", {
      channel: "presence-user-" + this.user.ID,
      token: "",
    });
  },
  async mounted() {
    // await this.$client.post("/Doctor/ListPendingAppointments", {});

    this.$refs.alarm.loop = true;

    //HS 20/05/2023 !!! tek seferlik alarmın başlatılması için eklendi.
    //this.alarmInterval = setInterval(() => this.playAlert(), 1000 * 60 * 4);
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    notifyList() {
      return this.$store.getters.getNotification;
    },
    notifyFilter() {
      return this.$store.state.auth.notifyFilter;
    },
  },
  methods: {
    async getLanguageList() {
      var res = await this.$client.post("/Localization/SystemLanguages");
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.languageList = res.data.Data.Items;
      }
    },
    async setLanguage(langCode) {
      await this.$store.dispatch("setLanguageLocalisationKey", langCode);
    },
    async markAllAsRead() {
      var res = await this.$client.post("/Notification/MarkAllAsRead", {
        UserId: this.user.ID,
      });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.user.UnreadNotificationCount = 0;
      }
    },

    async deleteNotification(id, index) {
      var res = await this.$client.post("Notification/Delete", {
        Id: id,
      });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.notifyList.splice(index, 1);
        this.$message.success(this.$localization('web-doctorlayout-notification-deleted-successfuly'));
      }
    },
    async deleteAllNotification() {
      var res = await this.$client.post("Notification/DeleteAll", {
        UserId: this.user.ID,
      });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.notifyFilter.Page = 1;
        await this.$store.dispatch("getNotification", this.notifyFilter);
        this.$message.success(this.$localization('web-doctorlayout-notifications-deleted-successfuly'));
      }
    },
    async notifySet() {
      await this.$store.dispatch("getNotification", this.notifyFilter);
    },
    async load() {
      await this.$store.dispatch("loadNotification", this.notifyFilter);
      this.notifyFilter.Page++;
    },
    async logout() {
      await this.$client.post("/Account/SignOut");
      localStorage.removeItem("token");
      this.$store.state.auth.user = null;
      this.$router.push({ name: "Login" });
    },
    //#region HS 20/05/2023 !!! alarm fonksiyonu 4 dakikada bir !!kaldırıldı
    playAlert() {
      if (this.$store.state.appointments.pendingAppointments.length > 0 && this.$route.name != "DoktorGorusmeOdasi") {
        var playPromise = this.$refs.appointmentAlarm.play();
        if (playPromise !== undefined) {
          // eslint-disable-next-line no-unused-vars
          playPromise.then(_ => {
            this.$refs.appointmentAlarm.play(0);
          }).catch();
        }
        this.$message.info("Bekleyen görüşme var");
      }
    },
    //#endregion
    //#region HS 20/05/2023 !!! alarm fonksiyonu sürekli
    playStopAlarm() {
      //#region HS 23/05/2023 listeye göre zil sesini ayarla
      var playAlertAudio = false;
      for (var appointment of this.$store.state.appointments.pendingAppointments) {
        if (appointment.Status == 1) {
          playAlertAudio = true
          break;
        }
      }
      //#endregion

      //#region HS 23/05/2023 listede bekleyen görüşme yoksa veya doktor görüşmedeyse zil sesini kapat
      if (this.$refs.alarm.duration > 0 && !this.$refs.alarm.paused && (!playAlertAudio || this.$route.name == "DoktorGorusmeOdasi")) {
        this.$refs.alarm.pause();
        this.$refs.alarm.currentTime = 0;
      }
      else if ((this.$refs.alarm.duration == 0 || this.$refs.alarm.paused) && playAlertAudio && this.$route.name != "DoktorGorusmeOdasi") {
        var playPromise = this.$refs.alarm.play(0);
        if (playPromise !== undefined) {
          // eslint-disable-next-line no-unused-vars
          playPromise.then(_ => {
            this.$refs.alarm.play(0);
            // eslint-disable-next-line no-unused-vars
          }).catch(/*error => {
            this.$message({
              showClose: true,
              message: this.$localization('web-doctor-nurse-layout-audio-interact'),
              // eslint-disable-next-line no-unused-vars
              onClose: _ => { this.playStopAlarm(); },
            });
          }*/);
        }
      }
      //#endregion
    },
    stopAlarm() {
      if (this.$refs.alarm.duration > 0 && !this.$refs.alarm.paused) {
        this.$refs.alarm.pause();
        this.$refs.alarm.currentTime = 0;
      }
    },
    //#endregion
  },
  watch: {
    "$route.params": function () {
      this.activeMenu = this.$route.path;
    },
  },
};
</script>
