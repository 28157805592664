export default {
    setDocumentCategories(state, data) {
        state.documentCategories = data
    },
    setDocuments(state, data) {
        state.documents = data
    },
    setFilter(state, data) {
        state.filter = data
    },
    deleteDocument(state, data) {
        state.documents.Items = state.documents.Items.filter(x => x.ID != data);
    },
    deleteDocumentCategory(state, data) {
        state.documentCategories.Items = state.documentCategories.Items.filter(x => x.ID != data);
    },
    setUploadDocuments(state, data) {
        state.uploadDocuments = data;
    },
    clearUploadDocuments(state) {
        state.uploadDocuments = {
            DocumentCategoryId: null,
            ParentDocumentId: null,
            Attachments: []
        };
    },
    setValidation(state, data){
        state.validationErrors = data;
    },
    setAddUpdateDocumentCategory(state, data) {
        state.addUpdateDocumentCategory = data;
    },
}