<template>
  <div class="h-100">
    <MeetingRoom  />
  </div>
</template>
<script>
import MeetingRoom from "../../components/room/MeetingRoom.vue";
export default {
  components: { MeetingRoom },
  data() {
    return {
      Appointment: {
        PatientDiagnosis: {
          Title: "",
          Details: "",
          Attachments: [],
        },
      },
 
    };
  },
  beforeCreate() {
     window.doNotThrowMe = true;
      this.$client.post('/Appointment/ShutdownOtherWindows', {})
      setTimeout(() => {
        window.doNotThrowMe = false;
      }, 1000);
  },
  async mounted() {
    var res = await this.$client.post("/Appointment/Get", {AppointmentId: this.$route.params.id});
    if(res.data.HasError){
      this.$message.error(res.data.ErrorMessage);
      
    }else {
      this.Appointment = res.data.Data.Appointment;
    }
  },
  methods: {
  },
};
</script>

<style>
#toolbar {
  padding: 9px;
  border: none;
  background-color: #f8fafb !important;
  border-radius: 5px 5px 0px 0px;
  font-family: "Roboto" !important;
}

.ql-snow .ql-stroke {
  stroke: #8697a8;
}
.ql-active > .ql-snow .ql-stroke {
  stroke: #8697a8;
}
.ql-container {
  min-height: 100px;
  border: none !important;
  background-color: #f8fafb;
  resize: both;
}
</style>
