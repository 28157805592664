import { client } from "@/helpers/axiosHelpers";
import { Message, MessageBox } from "element-ui";

export default {
  async getSupportRequestList(context, payload) {
    var res = await client.post("/SupportRequest/List", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setSupportRequestList", res.data.Data.SupportRequests);
      context.commit("setSupportRequestTotalCount", res.data.Data.TotalCount);
    }
  },
  async deleteSupportRequestItem(context,payload){
    MessageBox.confirm("Seçili talepleri tamamen silmek istediğiniz emin misiniz? Bu işlem geri alınamaz.", "Uyarı", {
      confirmButtonText: "Evet, Sil",
      confirmButtonClass: "pop-confirm-class",
      cancelButtonText: "Vazgeç",
      cancelButtonClass: "pop-cancel-class",
      type: "warning",
    })
      .then(async () => {
        await Promise.all(
          payload.map(async (element) => {
            var res = await client.post("/SupportRequest/Delete", { Id: element.ID });
            if (res.data.HasError) {
              Message.error(res.data.Message);
            }
          }),
        );
        await context.dispatch('getSupportRequestList', context.state.filter);
      })
      .catch(() => {});
  }
};
