import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  filter: {
    PageSize: 10,
    Page: 1,
    SearchTerm: null,
  },
  multiSelect: [],
  result: {
    TotalCount: 0,
  },
  list: [],
};
export default {
  getters,
  actions,
  mutations,
  state,
};
