<template>
  <div id="client-profile">
    <p class="p-head">{{ $localization('web-client-my-profile') }}</p>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>{{ $localization('web-client-my-profile') }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="profile">
        <div class="profile-top">
          <div class="profile-photo">
            <img v-if="user.Image != null" :src="user.Image" alt="" />
          </div>
          <div class="profile-name">{{ user.FirstName }} {{ user.LastName }}</div>
          <el-button class="edit" @click="openEditDialog(0)"> <i class="icon-drafts"></i> <span>{{ $localization('web-client-edit') }}</span>
          </el-button>
        </div>
        <div class="profile-bottom">
          <div class="item" v-if="user.InvoiceAddress.District != null && user.InvoiceAddress.City != null">
            <i class="icon-location"></i><span>{{ user.InvoiceAddress.District }}, {{ user.InvoiceAddress.City }}</span>
          </div>
          <div class="item" v-if="user.Email != null">
            <i class="icon-message"></i><span>{{ user.Email }}</span>
          </div>
          <div class="item">
            <i class="icon-mobile"></i><span>{{ user.PhoneNumber }}</span>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="address">
          <p>
            {{ $localization('web-client-invoice-address') }}
            <i class="icon-task" @click="openEditDialog(1)" style="cursor: pointer"></i>
          </p>
          <div class="info">
            <label class="data1">{{ $localization('web-client-city') }}</label>
            <label class="data2">{{ user.InvoiceAddress.City }}</label>
          </div>
          <div class="info">
            <label class="data1">{{ $localization('web-client-district') }}</label>
            <label class="data2">{{ user.InvoiceAddress.District }}</label>
          </div>
          <div class="info">
            <label class="data1">{{ $localization('web-client-postal-code') }}</label>
            <label class="data2">{{ user.InvoiceAddress.ZipCode }}</label>
          </div>

          <div class="info address-info">
            <label class="data1">{{ $localization('web-client-address') }}</label>
            <label class="data2">{{ user.InvoiceAddress.Address }}</label>
          </div>
        </div>
        <div class="last-conversations">
          <div class="title">
            <div>{{ $localization('web-client-passappointments') }} ({{ pastTotalCount }})</div>
          </div>
          <div class="profile-slider owl-theme owl-carousel" v-if="pastAppointment.length > 0">
            <div class="slider-item" v-for="item in pastAppointment" :key="item.ID">
              <div class="dr-card" v-if="item.DoctorUser != null">
                <div class="dr-photo">
                  <img :src="item.DoctorUser.Image" alt="" />
                </div>
                <div class="dr-information">
                  <div class="dr-name">
                    {{ item.DoctorUser.Specialist }}
                    {{ item.DoctorUser.Title }}
                  </div>
                  <div class="date">
                    {{ $moment(item.EndDate).format("DD.MM.YYYY") }}
                  </div>
                  <el-button class="detail" @click="goDetails(item.ID)"><i class="icon-arrow"></i>{{ $localization('web-client-goto-detail')
                  }}</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="empty" v-else>
            <div class="lottie-container appointment">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">{{ $localization('web-client-no-past-appointments') }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="end">
        <div class="payment">
          <div class="pay">{{ $localization('web-client-saved-cards') }} <i class="icon-task"></i></div>
          <div class="payment-top" v-if="user.PaymentMethods.length > 0">
            <CreditCardVue :data="form" />
          </div>
          <div class="payment-bottom" v-if="user.PaymentMethods.length > 0">
            <div class="card-info">
              <label class="data1">{{ $localization('web-client-card-type') }}</label>
              <label class="data2">{{ user.PaymentMethods[0].CardFamily }}</label>
            </div>
            <div class="card-info">
              <label class="data1">{{ $localization('web-client-cardholder') }}</label>
              <label class="data2">{{ user.PaymentMethods[0].Title }}</label>
            </div>

            <div class="card-info">
              <label class="data1">{{ $localization('web-client-cardno') }}</label>
              <label class="data2">{{ user.PaymentMethods[0].Bin }}</label>
            </div>
          </div>
          <div class="empty" v-if="user.PaymentMethods.length == 0">
            <div class="lottie-container">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">{{ $localization('web-client-no-payment-method') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="profileSettings" :width="screenWidth > 991 ? '40%' : '90%'" class="profile-settings-dialog" :close-on-click-modal="false">
      <div class="dialog">
        <div class="close" @click="profileSettings = false">
          <i class="icon-close"></i>
        </div>
        <div class="dialog-left">
          <div class="dialog-left-top">
            <input type="file" @change="uploadFile" style="display: none" ref="file" />
            <div class="image" @click="uploadFiles()" :style="{ backgroundImage: 'url(' + user.Image + ')' }">
              <i class="icon-camera-2"></i>
              <div>{{ $localization('web-client-upload-photo') }}</div>
            </div>
          </div>
          <div class="dialog-left-bottom">
            <ul>
              <li :class="{ active: activeTab == 0 ? true : false }" @click="activeTab = 0">
                <i class="icon-details"></i>
                <div>{{ $localization('web-client-personal-info') }}</div>
              </li>
              <li :class="{ active: activeTab == 1 ? true : false }" @click="activeTab = 1">
                <i class="icon-truck"></i>
                <div>{{ $localization('web-client-invoice-address') }}</div>
              </li>
              <li :class="{ active: activeTab == 2 ? true : false }" @click="activeTab = 2">
                <i class="icon-credit-card"></i>
                <div>{{ $localization('web-client-saved-cards') }}</div>
              </li>
              <li class="delete-account" @click="deleteAccount">
                <i class="icon-delete"></i>
                <div>{{ $localization('web-client-delete-my-account') }}</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="dialog-right" v-if="activeTab == 0">
          <div class="container">
            <div class="title2">{{ $localization('web-client-personal-info') }}</div>
            <div>{{ $localization('web-client-form-name') }}</div>
            <div class="input-container">
              <el-input v-model="userForm.FirstName" disabled></el-input>
              <span class="error">{{ $errorMessage("FirstName", ValidationErrors) }}</span>
            </div>
            <div>{{ $localization('web-client-form-surname') }}</div>
            <div class="input-container">
              <el-input v-model="userForm.LastName" disabled></el-input>
              <span class="error">{{ $errorMessage("LastName", ValidationErrors) }}</span>
            </div>

            <template v-if="userForm.IdentityNumber">
              <div>{{ $localization('web-client-form-identity-number') }}</div>
              <div class="input-container">
                <el-input v-model="userForm.IdentityNumber" disabled></el-input>
                <span class="error">{{ $errorMessage("IdentityNumber", ValidationErrors) }}</span>
              </div>
            </template>
            <template v-else>
              <div>{{ $localization('web-client-form-passport-number') }}</div>
              <div class="input-container">
                <el-input v-model="userForm.PassportNumber" disabled></el-input>
                <span class="error">{{ $errorMessage("PassportNumber", ValidationErrors) }}</span>
              </div>
            </template>

            <div>{{ $localization('web-client-form-cellphonenumber') }}</div>
            <div class="input-container-2">
              <div class="phone">
                <vue-phone-number-input v-model="userForm.PhoneNumber" @update="phoneFormatter = $event" :no-example="true" ref="phoneInput2" :translations="{
                  countrySelectorLabel: $localization('web-login-country-code'),
                  countrySelectorError: $localization('web-login-select-country-code'),
                  phoneNumberLabel: $localization('web-login-phone-number'),
                  example: $localization('web-login-example'),
                }" size="lg" default-country-code="TR" />
              </div>
              <span class="error">{{ $errorMessage("PhoneNumber", ValidationErrors) }}</span>
            </div>
            <div>{{ $localization('web-client-form-email') }}</div>

            <div class="input-container">
              <el-input v-model="userForm.Email"></el-input>
              <span class="error">{{ $errorMessage("Email", ValidationErrors) }}</span>
            </div>
            <div class="input-container">
              <div class="text">{{ $localization('web-client-form-birthdate') }}</div>
              <el-date-picker style="width: 100%" v-model="userForm.BirthDate" disabled format="dd.MM.yyyy" type="date" :placeholder="$localization('web-client-form-please-select')" default-value="1980-01-01" prefix-icon="none">
              </el-date-picker>
              <span class="error">{{ $errorMessage("Birthdate", ValidationErrors) }}</span>
            </div>

            <div class="input-container">
              <div>{{ $localization('web-client-form-address-optional') }}</div>
              <div class="location">
                <el-input :placeholder="$localization('web-client-form-enter-address')" v-model="userForm.Address"></el-input>
                <el-button class="save-button" @click="findLocation" :loading="loading" :disabled="loading">{{
                  $localization('web-client-form-find-address') }} </el-button>
              </div>

              <span class="error">{{ $errorMessage("Address", ValidationErrors) }}</span>
            </div>
            <div class="input-container">
              <div>{{ $localization('web-client-form-gender') }}</div>
              <el-select v-model="userForm.Gender" :placeholder="$localization('web-client-form-please-select')" v-if="genderEnums != null">
                <el-option v-for="(item, index) in genderEnums.Enums" :key="index" :label="item.DisplayName" :value="item.Key"> </el-option>
              </el-select>
              <span class="error">{{ $errorMessage("Gender", ValidationErrors) }}</span>
            </div>
            <div class="input-container">
              <div>{{ $localization('web-client-form-height') }}</div>
              <div class="location">
                <el-input :placeholder="$localization('web-client-form-enter-height')" v-model="userForm.Height"></el-input>
              </div>
              <span class="error">{{ $errorMessage("Height", ValidationErrors) }}</span>
            </div>
            <div class="input-container">
              <div>{{ $localization('web-client-form-kilo') }}</div>
              <div class="location">
                <el-input :placeholder="$localization('web-client-form-enter-kilo')" v-model="userForm.Weight"></el-input>
              </div>
              <span class="error">{{ $errorMessage("Weight", ValidationErrors) }}</span>
            </div>

            <el-button class="save-button" @click="updateProfileSettings()" :loading="loading" :disabled="loading"><i class="icon-refresh" v-if="!loading"></i>{{ $localization('web-client-form-save') }}</el-button>
          </div>
        </div>
        <div class="dialog-right" v-if="activeTab == 1">
          <div class="container">
            <div class="title2">{{ $localization('web-client-form-my-invoice-address') }}</div>
            <div>{{ $localization('web-client-form-country') }}</div>
            <div class="input-container">
              <el-input v-model="invoiceForm.Country"></el-input>
              <span class="error">{{ $errorMessage("Country", ValidationErrors) }}</span>
            </div>

            <div>{{ $localization('web-client-form-city') }}</div>
            <div class="input-container">
              <el-input v-model="invoiceForm.City"></el-input>
              <span class="error">{{ $errorMessage("City", ValidationErrors) }}</span>
            </div>

            <div>{{ $localization('web-client-form-district') }}</div>
            <div class="input-container">
              <el-input v-model="invoiceForm.District"></el-input>
              <span class="error">{{ $errorMessage("District", ValidationErrors) }}</span>
            </div>
            <div>{{ $localization('web-client-form-long-address') }}</div>
            <div class="input-container">
              <el-input v-model="invoiceForm.Address"></el-input>
              <span class="error">{{ $errorMessage("Address", ValidationErrors) }}</span>
            </div>

            <div>{{ $localization('web-client-form-postal-code') }}</div>
            <div class="input-container">
              <el-input v-model="invoiceForm.ZipCode"></el-input>
              <span class="error">{{ $errorMessage("ZipCode", ValidationErrors) }}</span>
            </div>
            <div>{{ $localization('web-client-form-phone') }}</div>
            <div class="input-container">
              <el-input v-model="invoiceForm.PhoneNumber"></el-input>
              <span class="error">{{ $errorMessage("PhoneNumber", ValidationErrors) }}</span>
            </div>
            <div>{{ $localization('web-client-form-identity-number-required') }}</div>
            <div class="input-container">
              <el-input v-model="invoiceForm.IdentityNo" v-mask="mask.Identity"></el-input>
              <span class="error">{{ $errorMessage("IdentityNo", ValidationErrors) }}</span>
            </div>
            <el-button class="save-button" @click="updateInvoiceSettings()" :loading="loading" :disabled="loading"><i class="icon-refresh" v-if="!loading"></i>{{ $localization('web-client-form-save') }}</el-button>
          </div>
        </div>
        <div class="dialog-right" v-if="activeTab == 2">
          <div class="container">
            <div class="title2">{{ $localization('web-client-form-my-saved-cards') }}</div>
            <div class="empty" v-if="user.PaymentMethods.length == 0" style="justify-content: center">
              <div class="lottie-container appointment">
                <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
                <div class="no-data-text">{{ $localization('web-client-form-no-payment-method-saved') }}</div>
              </div>
            </div>
            <ul class="credit-card-list">
              <li class="item" v-for="(card, index) in user.PaymentMethods" :key="index">
                <div class="icon" @click="deletePaymentMethod(card.Id)">
                  <i class="icon-cross"></i>
                </div>
                <div class="card-info">
                  <div class="title">
                    {{ card.Title }}
                  </div>
                  <div class="card-bin">
                    <div class="period-container">
                      <div class="period">
                        {{ card.Bin.slice(0, 4) }}
                      </div>
                      <div class="period">
                        {{ card.Bin.slice(4, 6) }}
                        <div class="icon-period" v-for="i in 2" :key="i"></div>
                      </div>
                      <div class="period">
                        <div class="icon-period" v-for="i in 4" :key="i"></div>
                      </div>
                      <div class="period">
                        <div class="icon-period" v-for="i in 4" :key="i"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-img">
                  <img src="/img/cardassocation/mastercard.svg" v-if="card.CardAssociation == 'MASTER_CARD'" alt="" />
                  <img src="/img/cardassocation/visa.svg" v-if="card.CardAssociation == 'VISA'" alt="" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="childrenSettings" :width="screenWidth > 991 ? '40%' : '90%'" class="children-settings-dialog" :close-on-click-modal="false">
      <div class="dialog">
        <div class="close" @click="childrenSettings = false">
          <i class="icon-close"></i>
        </div>
        <div class="dialog-children">
          <div class="container">
            <div class="title2">{{ $localization('web-client-add-child') }}</div>
            <div class="input-container">
              <div>{{ $localization('web-client-child-name') }}</div>
              <el-input v-model="childForm.FirstName" placeholder="..."></el-input>
              <span class="error">{{ $errorMessage("FirstName", validation) }}</span>
            </div>

            <div class="input-container">
              <div>{{ $localization('web-client-child-surname') }}</div>
              <el-input v-model="childForm.LastName" placeholder="..."></el-input>
              <span class="error">{{ $errorMessage("LastName", validation) }}</span>
            </div>

            <div class="input-container">
              <div>{{ $localization('web-client-child-identity') }}</div>
              <el-input maxlength="11" v-mask="mask.Identity" :placeholder="$localization('web-client-child-identitynumber')" class="tc" v-model="childForm.IdentityNumber"></el-input>
              <span class="error">{{ $errorMessage("IdentityNumber", validation) }}</span>
            </div>

            <div class="input input-container">
              <div class="text">{{ $localization('web-client-child-birthdate') }}</div>
              <el-date-picker style="width: 100%" v-model="childForm.BirthDate" format="dd.MM.yyyy" type="date" :placeholder="$localization('web-client-form-please-select')" prefix-icon="none"> </el-date-picker>
              <span class="error">{{ $errorMessage("BirthDate", validation) }}</span>
            </div>
            <div class="input-container">
              <div>{{ $localization('web-client-child-gender') }}</div>
              <el-select v-model="childForm.Gender" :placeholder="$localization('web-client-form-please-select')" v-if="genderEnums != null">
                <el-option v-for="(item, index) in genderEnums.Enums" :key="index" :label="item.DisplayName" :value="item.Key"> </el-option>
              </el-select>
              <span class="error">{{ $errorMessage("Gender", validation) }}</span>
            </div>

            <div class="height-weight">
              <div class="height">
                <div class="input-container">
                  <div>{{ $localization('web-client-child-height') }}</div>
                  <el-input v-model="childForm.Height" placeholder="...">
                    <template slot="append">{{ $localization('web-client-centimeter-short') }}</template>
                  </el-input>
                  <span class="error">{{ $errorMessage("Height", validation) }}</span>
                </div>
              </div>
              <div class="weight">
                <div class="input-container">
                  <div>{{ $localization('web-client-kilo') }}</div>
                  <el-input v-model="childForm.Weight" placeholder="...">
                    <template slot="append">{{ $localization('web-client-kilo-short') }}</template>
                  </el-input>
                  <span class="error">{{ $errorMessage("Weight", validation) }}</span>
                </div>
              </div>
            </div>
            <el-button class="save-button" @click="addUpdateChild()"><i class="icon-refresh"></i>{{ $localization('web-client-form-save') }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <div class="header-child">
      <div class="head-title">{{ $localization('web-client-my-children') }}</div>
      <div class="add-button" @click="openChildrenDialog(null)">
        <el-button><i></i></el-button>
      </div>
    </div>
    <el-table :data="childrenList" style="min-width: 340px;">
      <el-table-column sortable prop="name" :label="$localization('web-client-child-name-upper')" :min-width="200">
        <template slot-scope="scope">
          <div class="user-info">
            <div class="user-avatar">
              <div class="short">{{ scope.row.FirstName[0] + scope.row.LastName[0] }}</div>
            </div>
            <div class="user-name">
              <span>{{ scope.row.FirstName + " " + scope.row.LastName }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column sortable prop="BirthDate" :label="$localization('web-client-child-birthdate-upper')" :min-width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.BirthDate">{{ $moment(scope.row.BirthDate).format("DD-MM-YYYY") }}</span>
          <span v-else></span>
        </template>
      </el-table-column>
      <el-table-column sortable :label="$localization('web-client-child-gender-upper')" :min-width="110">
        <template slot-scope="scope">
          <span v-if="scope.row.Gender"> {{ getEnums.find((x) => x.Title == "Gender").Enums.find((x) => x.Key == scope.row.Gender).DisplayName }}
          </span>
          <span v-else></span>
        </template>
      </el-table-column>
      <el-table-column sortable :label="$localization('web-client-child-height-upper')">
        <template slot-scope="scope">
          <span v-if="scope.row.Height">{{ scope.row.Height }}</span>
          <span v-else></span>
        </template>
      </el-table-column>
      <el-table-column sortable :label="$localization('web-client-child-kilo-upper')">
        <template slot-scope="scope">
          <span v-if="scope.row.Weight">{{ scope.row.Weight }}</span>
          <span v-else></span>
        </template>
      </el-table-column>
      <el-table-column style="align:center" width="200" label="">
        <template slot-scope="scope">
          <el-dropdown>
            <span class="el-dropdown-link actions-menu"> <i class="icon-dots"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <span @click="openChildrenDialog(scope.row)" style="display: block; width: 100%">{{ $localization('web-client-see-details') }}</span>
              </el-dropdown-item>
              <el-dropdown-item divided><span class="tex-danger" style="display: block" @click="deleteChild(scope.row.ID)">{{
                $localization('web-client-delete') }}</span></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
      <template slot="empty">
        <div class="lottie-container">
          <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
          <div class="no-data-text">{{ $localization('web-client-no-data-found') }}</div>
        </div>
      </template>
    </el-table>
  </div>
</template>

<script>
import CreditCardVue from "../../components/CreditCard.vue";
export default {
  data() {
    return {
      mask: {
        Identity: "###########",
        PhoneNumber: "+############",
      },
      phoneFormatter: null,
      loading: false,
      ValidationErrors: [],
      screenWidth: 0,
      activeTab: 0,
      userForm: JSON.parse(JSON.stringify(this.$store.getters.getUser)),
      invoiceForm: JSON.parse(JSON.stringify(this.$store.getters.getUser.InvoiceAddress)),
      profileSettingsForm: {
        cardOwner: "",
        cardNumber: "",
        expirationDate: "",
        cvvCode: "",
      },

      value: "",
      profileSettings: false,
      childrenSettings: false,
      form: {
        CardName: this.$store.getters.getUser.PaymentMethods.length > 0 ? this.$store.getters.getUser.PaymentMethods[0].Title : "",
        CardNO: this.$store.getters.getUser.PaymentMethods.length > 0 ? this.$store.getters.getUser.PaymentMethods[0].Bin : "",
        CVV: "",
        ExpireDate: null,
      },
      pastAppointment: [],
      pastTotalCount: 0,
      childForm: {
        ParentId: null,
        FirstName: null,
        LastName: null,
        Gender: null,
        Height: null,
        Weight: null,
        Title: null,
        ID: null,
        BirthDate: null,
        IdentityNumber: null,
      },
      long: null,
      lat: null,
      adress: null,
    };
  },
  components: {
    CreditCardVue,
  },
  methods: {
    async deleteAccount() {
      this.$confirm(this.$localization('web-client-are-you-sure-delete-account'), this.$localization('web-client-warning'), {
        confirmButtonText: this.$localization('web-client-yes'),
        confirmButtonClass: "pop-confirm-class",
        cancelButtonText: this.$localization('web-client-no'),
        cancelButtonClass: "pop-cancel-class",
        type: "warning",
      })
        .then(async () => {
          console.log("sil");
          var res = await this.$client.post("/Account/Delete", {});
          if (res.data.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            await this.$client.post("/Account/SignOut");
            localStorage.removeItem("token");
            this.$store.state.auth.user = null;
            this.$router.push({ name: "Login" });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$localization('web-client-process-cancelled'),
          });
        });
    },
    findLocation() {
      var self = this;
      navigator.geolocation.getCurrentPosition(function (item) {
        var url = "https://nominatim.openstreetmap.org/reverse?format=xml&lat=" + item.coords.latitude + "&lon=" + item.coords.longitude + "&zoom=18&addressdetails=1";
        window.jQuery.get(url, function (data) {
          self.userForm.Address = data.getElementsByTagName("province")[0].childNodes[0].nodeValue + " / " + data.getElementsByTagName("town")[0].childNodes[0].nodeValue;
          self.userForm.Latitude = item.coords.latitude.toString();
          self.userForm.Longitude = item.coords.longitude.toString();
        });
      });
    },
    async deleteChild(Id) {
      await this.$store.dispatch("deleteChild", { Id });
    },
    clearForm() {
      this.validation = [];
      this.childForm = {
        ParentId: null,
        FirstName: null,
        LastName: null,
        Gender: null,
        Height: null,
        Weight: null,
        Title: null,
        ID: null,
        BirthDate: null,
        IdentityNumber: null,
      };
    },
    async addUpdateChild() {
      this.$store.commit("setChildrenValidation", []);
      if (this.childForm.IdentityNumber != null) {
        if (this.childForm.IdentityNumber.length != 11) {
          this.$message.error(this.$localization('web-client-identity-number-must-be-11-digits'));
          return;
        }
      } else {
        this.$message.error(this.$localization('web-client-identity-number-required'));
        return;
      }
      if (this.childForm.Weight == "") {
        this.childForm.Weight = null;
      }
      if (this.childForm.Height == "") {
        this.childForm.Height = null;
      }
      await this.$store.dispatch("addUpdateChild", this.childForm);

      if (this.validation.length == 0) {
        await this.$store.dispatch("getChildrenList", this.filter);
        this.childForm = {
          ParentId: null,
          FirstName: null,
          LastName: null,
          Gender: null,
          Height: null,
          Weight: null,
          Title: null,
          ID: null,
          BirthDate: null,
          IdentityNumber: null,
        };
        this.childrenSettings = false;
      }
    },
    async openChildrenDialog(item) {
      await this.$store.commit("setChildrenValidation", []);
      if (item != null) {
        this.childForm.ID = item.ID;
        this.childForm.Title = item.Title;
        this.childForm.ParentId = item.ParentId;
        this.childForm.FirstName = item.FirstName;
        this.childForm.LastName = item.LastName;
        this.childForm.Gender = item.Gender;
        this.childForm.Weight = item.Weight;
        this.childForm.Height = item.Height;
        this.childForm.BirthDate = item.BirthDate;
        this.childForm.IdentityNumber = item.IdentityNumber;
      } else {
        this.childForm.ID = null;
        this.childForm.Title = null;
        this.childForm.ParentId = null;
        this.childForm.FirstName = null;
        this.childForm.LastName = null;
        this.childForm.Gender = null;
        this.childForm.Weight = null;
        this.childForm.Height = null;
        this.childForm.BirthDate = null;
        this.childForm.IdentityNumber = null;
      }
      this.childrenSettings = true;
    },

    async updateProfileSettings() {
      this.loading = true;
      this.ValidationErrors = [];

      if (this.phoneFormatter != null) {
        if (this.phoneFormatter.isValid != false) {
          this.userForm.PhoneNumber = this.phoneFormatter.formatInternational.replaceAll(" ", "");
        } else {
          this.loading = false;
        }
      } else {
        this.loading = false;

        this.$message.warning(this.$localization('web-client-phone-number-must-be-valid'));
        return;
      }
      var updateObj = {
        FirstName: this.userForm.FirstName,
        LastName: this.userForm.LastName,
        PhoneNumber: this.userForm.PhoneNumber,
        Email: this.userForm.Email,
        BirthDate: this.userForm.BirthDate,
        Gender: this.userForm.Gender,
        Address: this.userForm.Address,
        Latitude: this.userForm.Latitude,
        Longitude: this.userForm.Longitude,
        Weight: this.userForm.Weight,
        Height: this.userForm.Height,
      };
      if (this.userForm.Weight == "") {
        updateObj.Weight = null;
      }
      if (this.userForm.Height == "") {
        updateObj.Height = null;
      }
      var res = await this.$client.post("/Account/UpdateUserPersonalInfo", updateObj);
      this.ValidationErrors = res.data.ValidationErrors;
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        await this.$store.dispatch("getUser");
        this.close();
      }
      this.loading = false;
    },

    async updateInvoiceSettings() {
      this.ValidationErrors = [];
      var updateObj = {
        Country: this.invoiceForm.Country,
        City: this.invoiceForm.City,
        District: this.invoiceForm.District,
        Address: this.invoiceForm.Address,
        ZipCode: this.invoiceForm.ZipCode,
        PhoneNumber: this.invoiceForm.PhoneNumber,
        IdentityNo: this.invoiceForm.IdentityNo,
      };
      var res = await this.$client.post("/Account/UpdateUserInvoiceInfo", updateObj);
      this.ValidationErrors = res.data.ValidationErrors;

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        await this.$store.dispatch("getUser");
        this.$message.success(res.data.Message);
        this.close();
      }
    },
    close() {
      this.profileSettings = false;
    },

    openEditDialog(tabIndex) {
      this.activeTab = tabIndex;
      this.profileSettings = false;
      this.invoiceForm = JSON.parse(JSON.stringify(this.$store.getters.getUser.InvoiceAddress));
      this.userForm = JSON.parse(JSON.stringify(this.$store.getters.getUser));
      this.profileSettings = true;
    },
    goDetails(id) {
      this.$router.push("/kullanici/gorusme-detay/" + id);
    },
    async uploadFile() {
      let file = this.$refs.file.files[0];
      var fd = new FormData();
      fd.append("file", file);
      var res = await this.$client.post("/Account/UploadFile?type=3", fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (!res.data.HasError) {
        var uploadImage = await this.$client.post("/Account/UpdateProfilePicture", { image: res.data.Item.Url });
        if (uploadImage.data.HasError) {
          this.$message.error(uploadImage.data.Message);
        } else {
          this.$message.success(uploadImage.data.Message);
          this.user.Image = res.data.Item.Url;
        }
      } else {
        this.$message.error(res.data.Message);
      }
      this.$refs.file.value = null;
    },
    uploadFiles() {
      this.$refs.file.click();
    },
    async getPastAppointment() {
      var res = await this.$client.post("/Appointment/List", {
        PageSize: 5,
        Page: 1,
        Status: 3,
        PastAppointments: true,
        SearchTerm: null,
      });

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.pastAppointment = res.data.Data.Appointments;
        this.pastTotalCount = res.data.Data.TotalCount;
      }
    },
    async deletePaymentMethod(id) {
      this.$confirm(this.$localization('web-client-are-you-sure-to-delete-card'), this.$localization('web-client-warning'), {
        confirmButtonText: this.$localization('web-client-yes-delete'),
        confirmButtonClass: "pop-confirm-class",
        cancelButtonText: this.$localization('web-client-cancel'),
        cancelButtonClass: "pop-cancel-class",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Account/DeletePaymentMethod", {
            ID: id,
          });
          if (!res.data.HasError) {
            this.$message.success(res.data.Message);
            this.user.PaymentMethods = this.user.PaymentMethods.filter((x) => x.Id != id);
          } else {
            this.$message.error(res.data.Message);
          }
        })
        .catch(() => { });
    },
  },
  async beforeMount() {
    await this.getPastAppointment();
    await this.$store.dispatch("getChildrenList", this.filter);
    window.jQuery(document).ready(function () {
      window.jQuery(".profile-slider").owlCarousel({
        items: 1,
        margin: 30,
      });
    });
  },
  computed: {
    genderEnums() {
      return this.$store.getters.getEnums.find((x) => x.Title == "Gender");
    },
    user() {
      return this.$store.getters.getUser;
    },
    filter() {
      return this.$store.getters.getChildrenFilter;
    },
    childrenList() {
      return this.$store.getters.getChildrenList;
    },
    validation() {
      return this.$store.getters.getChildrenValidation;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },
};
</script>

<style lang="less">
#client-profile {
  .location {
    display: flex;
    align-items: center;

    button {
      margin-top: 0 !important;
      width: 100px !important;
      padding: 0 10px !important;
    }
  }

  .p-head {
    margin-top: 0;
  }

  padding: 42px 30px;

  //15/06/2023 HS !!! mobil için yeni boyut eklendi
  @media screen and (max-width: 550px) {
    padding: 20px 10px;
  }

  .header-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
    min-width: 340px; //15/06/2023 HS !!! mobil için minimum boyut eklendi

    .head-title {
      font-weight: 700;
      font-size: 30px;
      line-height: 23px;
      color: #44566c;
    }

    .add-button {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        height: 40px;
        width: 40px;
        background-color: #54d0c8;
        border: none;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);

        &:hover {
          background-color: #54d0c8;
          border-color: none;
          color: white;
        }

        i {
          width: 24px;
          height: 24px;
          display: block;
          mask: url("../../assets/img/icons/Plus.svg") no-repeat center;
          -webkit-mask: url("../../assets/img/icons/Plus.svg") no-repeat center;
          background-color: white;
        }
      }
    }
  }

  .user-info {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    .user-avatar {
      flex-shrink: 0;
      width: 30px;
      height: 30px;

      .short {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: orange;
        height: 100%;
        border-radius: 50%;
        color: white;
        font-weight: bold;
        font-size: 13px;
      }
    }
  }

  .el-table {
    .el-table__header-wrapper {
      .el-table__header {
        padding-left: 24px;
      }
    }

    .el-table__body-wrapper {
      .el-table__body {
        .el-table__row {
          .el-table__cell {
            padding-left: 24px;
          }
        }
      }
    }
  }

  .el-select__caret {
    // background-color: #f8fafb;
    color: #b3c0ce;
  }

  p {
    font-weight: 700;
    font-size: 30px;
    color: #44566c;
  }

  .el-select {
    width: 100%;
  }

  .main {
    display: flex;
    gap: 30px;

    @media screen and (max-width: 991px) {
      flex-direction: column;

      .profile,
      .middle,
      .end {
        width: 100% !important;
      }
    }

    .profile-name {
      font-weight: 700;
      font-size: 30px;
      line-height: 35px;
      color: #44566c;
      margin-top: 0;
      text-align: center;
    }

    .profile {
      width: 24.5%;
      // height: 566px;
      box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
      background-color: #fff;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      min-width: 340px;
    }

    .profile-top {
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
    }

    .profile-bottom {
      background-color: #f8fafb;
      padding: 30px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 20px;

      .item {
        display: flex;
        position: relative !important;
        flex-direction: row;
        justify-content: flex-start;
        gap: 0.4rem;
        align-items: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #44566c;

        .i {
          position: absolute !important;
        }

        &> :last-child {
          min-width: 150px;
        }
      }

      i {
        color: #8697ab;
      }
    }

    .profile-photo {
      width: 220px;
      height: 220px;
      border-radius: 40px;
      overflow: hidden;
      box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.08);

      img {
        width: 100%;
      }
    }

    .edit {
      background: #54d0c8;
      border-radius: 50px;
      box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);

      span {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 0.4rem;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #fff;
        border: none;
      }
    }
  }

  .middle {
    width: 37.75%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    min-width: 340px; //15/06/2023 HS !!! mobil için minimum boyut eklendi

    .address {
      background-color: #fff;
      box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      padding: 30px;
      gap: 1rem;

      p {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #44566c;
      }

      &>.info {
        display: flex;
        justify-content: space-between;
        gap: 60px;

        .data1 {
          font-weight: 400;
          min-width: 40px;
          font-size: 15px;
          color: #8697a8;
          align-items: flex-end;
        }

        .data2 {
          color: #44566c;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
        }

        &.address-info {
          &>.data2 {
            word-break: break-word;
            text-align: right;
          }
        }
      }
    }

    .icon-task {
      width: 22px;
      height: 22px;
      background-color: #44566c;
      float: right;
    }

    .last-conversations {
      width: 100%;
      background-color: #fff;
      box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
      border-radius: 15px;
      padding: 25px;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;

        a {
          font-weight: 400;
          font-size: 15px;
          text-decoration: none;
          color: #54d0c8;
        }

        div {
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
          color: #44566c;
        }
      }

      .profile-slider {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        height: 100%;

        .owl-carousel {
          overflow: hidden;
        }
      }
    }

    .dr-card {
      overflow: hidden;
      background-color: #f8fafb;
      border-radius: 15px;
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
    }

    .dr-information {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 25px;
      width: 100%;

      .date {
        margin-top: 5px;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;

        color: #44566c;
        margin-bottom: 30px;
      }

      .detail {
        background-color: #54d0c8;
        width: 137px;
        height: 40px;
        color: #fff;
        font-weight: 500;
        border-radius: 5px;
        font-size: 15px;
      }

      i {
        float: left;
      }
    }

    .dr-photo {
      flex-shrink: 0;
      background-color: #8697a8;
      width: 148px;
      height: 183px;
      border-radius: 15px 0 0 15px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .end {
    width: 37.75%;
    display: flex;
    gap: 30px;
    min-width: 340px;

    .payment {
      height: 100%;
      width: 100%;
      box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
      background-color: #fff;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      overflow: hidden;

      .empty {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }

    .pay {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #44566c;
      padding: 26px;
    }

    .payment-top {
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .payment-bottom {
      background-color: #f8fafb;
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .data1 {
      font-weight: 400;
      font-size: 15px;
      color: #8697a8;
    }

    .data2 {
      color: #44566c;
      font-weight: 500;
      font-size: 15px;
      float: right;
    }
  }

  .profile-settings-dialog {
    .el-dialog {
      min-width: 600px;
      border-radius: 1rem;
      overflow: hidden;

      //15/06/2023 HS !!! mobil için yeni boyut eklendi
      @media screen and (max-width: 550px) {
        min-width: 340px;
      }

      .el-dialog__header {
        display: none;
      }

      .el-dialog__body {
        padding: 0;

        .dialog {
          min-width: 600px;
          position: relative;

          //15/06/2023 HS !!! mobil için yeni boyut eklendi
          @media screen and (max-width: 670px) {
            min-width: 340px;
          }


          .dialog-right {
            .container {
              padding-left: 20px;
              padding-right: 20px;

              //15/06/2023 HS !!! mobil için yeni boyut eklendi
              @media screen and (max-width: 670px) {
                padding-left: 0;
                padding-right: 0;
              }

              .input-container {
                margin-bottom: 15px;

                .el-date-editor {
                  .el-input__inner {
                    padding-left: 15px;
                  }
                }
              }
            }
          }

          .close {
            position: absolute;
            right: 1rem;
            cursor: pointer !important;
            top: 1rem;
            width: 28px;
            height: 28px;
            padding: 3px;
            border-radius: 50%;
            background-color: #eaedf0;

            .icon-close {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .children-settings-dialog {
    .el-dialog {
      min-width: 600px;
      border-radius: 1rem;
      overflow: hidden;

      //15/06/2023 HS !!! mobil için yeni boyut eklendi
      @media screen and (max-width: 670px) {
        min-width: 340px;
      }

      .el-dialog__header {
        display: none;
      }

      .el-dialog__body {
        padding: 0;

        .dialog {
          min-width: 600px;
          position: relative;

          //15/06/2023 HS !!! mobil için yeni boyut eklendi
          @media screen and (max-width: 670px) {
            min-width: 340px;
          }

          .dialog-children {
            padding: 14px;
            width: 100%;
            padding-bottom: 20px;

            .container {
              padding-left: 20px;
              padding-right: 20px;

              .input {
                .el-date-editor {
                  .el-input__inner {
                    padding-left: 15px;
                  }
                }
              }
            }

            .height-weight {
              width: 100%;
              display: flex;
              gap: 8px;

              .height {
                width: 50%;
              }

              .weight {
                width: 50%;
              }

              .input-container {
                .el-input-group {
                  .el-input-group__append {
                    background-color: #fff;
                  }
                }
              }
            }

            .save-button {
              margin-top: 20px;
            }
          }

          .close {
            position: absolute;
            right: 1rem;
            cursor: pointer !important;
            top: 1rem;
            width: 28px;
            height: 28px;
            padding: 3px;
            border-radius: 50%;
            background-color: #eaedf0;

            .icon-close {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .input-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  .dialog {
    display: flex;

    @media screen and (max-width: 991px) {
      flex-direction: column;

      .dialog-left,
      .dialog-right {
        width: 100% !important;

        .container {
          .el-select {
            .el-input {
              .el-input__suffix {
                .el-input__suffix-inner {
                  .el-select__caret {
                    background: transparent !important;
                  }
                }
              }
            }
          }
        }
      }

      .dialog-left {
        .dialog-left-bottom {
          ul {
            margin: 0;
            flex-direction: row;

            li {
              flex-direction: column;
              height: auto;
            }
          }
        }
      }
    }

    .dialog-left-bottom {
      background: #f8fafb;
      padding-left: 8px;

      ul {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 0;
      }

      li {
        cursor: pointer;
        display: flex;
        list-style: none;
        align-items: center;
        padding: 10px;
        gap: 10px;
        width: 100%;

        //15/06/2023 HS !!! mobil için yeni boyut eklendi
        @media screen and (max-width: 550px) {
          padding: 5px;
          text-align: center;
        }

        &.active {
          background-color: white;
        }

        &.delete-account {
          background-color: #ff4d4f;

          &>div {
            color: white;
          }

          &>i {
            background-color: white;
          }
        }

        height: 56px;

        div {
          font-weight: 500;
          font-size: 17px;
          color: #44566c;
        }
      }
    }

    .dialog-left {
      width: 40%;
      display: flex;
      flex-direction: column;
      background-color: #f8fafb;
    }

    .dialog-left-top {
      padding-top: 2rem;
      display: flex;
      align-self: center;
      margin-bottom: 55px;

      .image {
        cursor: pointer;
        width: 170px;
        background-position: center;
        background-size: cover !important;
        height: 170px;
        background: #44566c;
        opacity: 0.6;
        border-radius: 40px;
        box-shadow: 0px 16px 32px rgba(169, 194, 209, 0.55), 0px 8px 16px rgba(169, 194, 209, 0.45);
        display: flex;
        flex-direction: column;
        justify-content: center;

        div,
        i {
          color: #fff;
          font-weight: 500;
          font-size: 15px;
          align-self: center;
        }
      }
    }

    .dialog-right {
      padding: 1rem;
      width: 60%;
      display: flex;
      flex-direction: column;

      &>.container {
        .credit-card-list {
          li {
            cursor: unset;

            .icon {
              cursor: pointer;
            }
          }
        }

        width: 100%;

        .input-container {
          display: flex;
          gap: 5px;
          flex-direction: column;
          margin-bottom: 15px;
        }

        .input-container-2 {
          width: 100%;
          display: flex;
          margin-bottom: 15px;
          flex-direction: column;
          gap: 5px;

          input {
            border-radius: 8px !important;
          }

          .phone {
            padding: 2px;
            width: 100%;

            .input-tel {
              width: 100%;
            }

            .vue-phone-number-input {
              width: 100%;
              gap: 20px;

              .select-country-container {
                .country-selector {
                  &:hover {
                    z-index: unset !important;
                  }
                }
              }
            }

            .flex-1 {
              width: 100%;

              input {
                width: 100%;
              }
            }

            display: flex;
            flex-direction: row;
            gap: 10px;

            .vue-country-select {
              border: none;
              background-color: #f8fafb;
              border-radius: 4px;

              .dropdown.open {
                .dropdown-list {
                  border: none;

                  .dropdown-item {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    &.highlighted {
                      background-color: #f8fafb;
                    }

                    &.last-preferred {
                      border-bottom: 1px solid #54d0c8;
                    }
                  }

                  &::-webkit-scrollbar {
                    width: 5px;
                  }

                  &::-webkit-scrollbar-thumb {
                    border: 4px solid rgba(0, 0, 0, 0.2);
                    background-clip: padding-box;
                    border-radius: 4px;
                  }

                  /* Track */
                  &::-webkit-scrollbar-track {
                    background: white;
                  }
                }
              }
            }

            .phone-2 {
              position: relative;
              width: 100%;

              &::after {
                content: "";
                position: absolute;
                width: 22px;
                height: 22px;
                left: 13px;
                top: 9px;
                z-index: 2;
                background-color: #44566c;
                mask: url("/img/icons/icon-mobile.svg") no-repeat center;
                -webkit-mask: url("/img/icons/icon-mobile.svg") no-repeat center;
              }

              input {
                z-index: 1;
                border: none;
                background-color: #f8fafb;
                text-indent: 48px;
                padding: 0;
                font-size: 15px;
                font-weight: 400;
                line-height: 21px;
                width: 100%;
              }
            }
          }
        }

        .el-input {
          input {
            width: 100%;
          }
        }

        &>div {
          display: flex;

          i {
            align-content: center;
            margin-left: 5px;
          }
        }

        .dt-container {
          display: flex;
          gap: 15px;

          .el-select {
            width: 33.333%;

            input {
              width: 100%;
            }
          }
        }
      }
    }

    .image {
      width: 170px;
      height: 170px;

      opacity: 0.6;
      border-radius: 40px;
      box-shadow: 0px 16px 32px rgba(169, 194, 209, 0.55), 0px 8px 16px rgba(169, 194, 209, 0.45);
      display: flex;
      flex-direction: column;

      div,
      i {
        color: #fff;
        font-weight: 500;
        font-size: 15px;
        align-self: center;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-self: center;

      &>input,
      &>select {
        background-color: #f8fafb;
        width: 280px;
        height: 42.22px;
        border: none;
      }

      &>div {
        color: #8697a8;
        font-weight: 400;
        font-size: 15px;
      }

      .title2 {
        font-weight: 700;
        font-size: 30px;
        color: #44566c;
        padding-top: 15px;
        margin-bottom: 15px;
      }

      .save-button {
        box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
        background-color: #54d0c8;
        color: #fff;
        width: 116px;
        height: 40px;
        font-weight: 500;
        font-size: 15px;
        align-self: flex-end;
        margin-top: 10px;
        border-radius: 5px;

        i {
          float: left;
        }
      }
    }
  }
}
</style>
