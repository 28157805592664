import { client } from "@/helpers/axiosHelpers";
import { Message } from "element-ui";
export default {
  async getTransactionList(context) {
    var response = await client.post("/History/List", context.state.filter);
    if (response.data.HasError) {
      Message.error(response.data.Message);
    } else {
      context.commit("setTransactionList", response.data.Data.Items);
      context.commit("setTransactionTotalCount", response.data.Data.TotalCount);
      context.commit("setTransactionPageCount", response.data.Data.PageCount);
    }
  },
};
