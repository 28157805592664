<template>
  <div id="interview">
    <div class="did-you-know-these">
      <div class="slider owl-theme owl-carousel">
        <div class="item">
          <div class="item-inner">
            <h2>{{ $localization("web-interview-important-warning") }}</h2>
            <p>{{ $localization("web-interview-infotext1") }}</p>
          </div>
        </div>
        <div class="item">
          <div class="item-inner">
            <h2>{{ $localization("web-interview-important-warning") }}</h2>
            <p>{{ $localization("web-interview-infotext2") }}</p>
          </div>
        </div>
        <div class="item">
          <div class="item-inner">
            <h2>{{ $localization("web-interview-important-warning") }}</h2>
            <p>{{ $localization("web-interview-infotext3") }}</p>
          </div>
        </div>
        <div class="item">
          <div class="item-inner">
            <h2>{{ $localization("web-interview-important-warning") }}</h2>
            <p>{{ $localization("web-interview-infotext4") }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="appointment-details">
      <div class="all-interviews">
        <!--<div class="type" :class="meetingType == 1 ? 'active' : ''" @click="setMeetingType(1)">
          <span> {{ $localization("web-interview-start-appointment-now") }} </span>
        </div>-->
        <div class="type" :class="meetingType == 2 ? 'active' : ''"><!--@click="setMeetingType(2)"-->
          <span> {{ $localization("web-interview-start-appointment-specialist") }} </span>
        </div>
        <!-- <div class="type" :class="meetingType == 3 ? 'active' : ''" @click="setMeetingType(3)">Diğer Sağlık Personelleri</div> -->
      </div>
      <div class="interview-information">
        <div class="title-interview">{{ $localization("web-interview-meet-infos") }}</div>

        <div class="form">
          <div class="form-item">
            <div class="title">{{ $localization("web-interview-patient") }}</div>
            <el-select v-model="form.PatientId" :placeholder="$localization('web-interview-please-select')">
              <el-option v-for="(item, index) in patient" :key="index"
                :label="item.ParentId != null || item.ParentId != undefined ? item.FirstName + ' ' + item.LastName + ' ' + $localization('web-interview-kid') : item.FirstName + ' ' + item.LastName + ' ' + $localization('web-interview-me')"
                :value="item.ID"></el-option>
            </el-select>
            <span class="error">{{ $errorMessage("patientId", ValidationErrors) }}</span>
          </div>
          <div class="form-item">
            <div class="date">
              <div class="title">{{ $localization("web-interview-meet-date") }}</div>
              <el-select :disabled="true" v-model="form.InterviewType" :placeholder="$localization('web-interview-please-select')" v-if="getEnums != null">
                <el-option v-for="item in getEnums.find((x) => x.Title == 'InterviewType').Enums" :key="item.Key" :label="item.DisplayName" :value="item.Key"></el-option>
              </el-select>
              <span class="error">{{ $errorMessage("InterviewType", ValidationErrors) }}</span>
            </div>
          </div>

          <template v-if="meetingType == 2 || meetingType == 3">
            <div class="form-item">
              <div class="date">
                <div class="title">{{ $localization("web-interview-speciality") }}</div>
                <el-select v-model="SpecialityId" :placeholder="$localization('web-interview-please-select-speciality')" @change="setDoctors">
                  <el-option v-for="item in allSpecialities" :key="item.ID" :label="item.Title" :value="item.ID"></el-option>
                </el-select>
                <!-- <el-select v-model="SpecialityId" placeholder="Branş / Uzmanlık Seçiniz.." @change="setDoctors" v-if="meetingType == 2">
                  <el-option v-for="item in allSpecialities.filter((x) => x.Type == true)" :key="item.ID" :label="item.Title" :value="item.ID"></el-option>
                </el-select>
                <el-select v-model="SpecialityId" placeholder="Branş / Uzmanlık Seçiniz.." @change="setDoctors" v-if="meetingType == 3">
                  <el-option v-for="item in allSpecialities.filter((x) => x.Type == false)" :key="item.ID" :label="item.Title" :value="item.ID"></el-option>
                </el-select> -->
                <span class="error">{{ $errorMessage("Speciality", ValidationErrors) }}</span>
              </div>
            </div>
            <div class="form-item">
              <div class="date">
                <div class="title">{{ $localization("web-interview-consultant") }}</div>
                <el-select v-model="form.DoctorId" :placeholder="$localization('web-interview-please-select-consultant')" :disabled="!SpecialityId" @change="resetDateHourAndPrice">
                  <el-option v-for="item in doctorsList" :key="item.ID" :label="item.Title" :value="item.ID"></el-option>
                </el-select>
                <span class="error">{{ $errorMessage("Speciality", ValidationErrors) }}</span>
              </div>
            </div>
            <template v-if="form.InterviewType == 2">
              <div class="form-item">
                <div class="date">
                  <div class="title">{{ $localization("web-interview-appointment-date") }}</div>
                  <el-date-picker v-model="date" type="date" format="dd-MM-yyyy" :picker-options="pickerOptions" :clearable="false" :placeholder="$localization('web-interview-please-select-date')" @change="setEnableDoctorHour"
                    :disabled="!(form.DoctorId && form.DoctorId != '')"> </el-date-picker>
                  <span class="error">{{ $errorMessage("InterviewDate", ValidationErrors) }}</span>
                </div>
              </div>
              <div class="form-item">
                <div class="date">
                  <div class="title">{{ $localization("web-interview-appointment-hour") }}</div>
                  <el-select v-model="hour" :placeholder="$localization('web-interview-please-select-hour')" :disabled="!(date && date != '')" :no-data-text="$localization('web-interview-no-appointment-hour-found-available')">
                    <el-option v-for="item in enableDoctorHours" :key="item.TimeStr" :label="item.TimeStr" :value="item.TimeStr"></el-option>
                  </el-select>
                  <span class="error">{{ $errorMessage("InterviewHour", ValidationErrors) }}</span>
                </div>
              </div>
              <div v-if="appointmentPrice" class="form-item">
                <div class="date">
                  <el-row style="text-align: center;">
                    <el-col :span="12">
                      <div class="title">{{ $localization("web-interview-total-price") }}</div>
                      <div class="text">{{ appointmentPrice }} {{ $store.state.auth.language == "tr" ? "TL" : "USD" }}</div>
                    </el-col>
                    <el-col :span="12">
                      <div class="title">{{ $localization("web-doctor-home-minute")[0].toUpperCase() + $localization("web-doctor-home-minute").slice(1) }}</div>
                      <div class="text">{{ this.doctorsList.find(doctor => doctor.ID === this.form.DoctorId).AppointmentDuration }} </div>
                    </el-col>
                  </el-row>

                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>

    <div class="credit-card-container">
      <div class="credit-card">
        <div class="payment">
          <div class="title-payment">{{ $localization("web-interview-payment") }}</div>
          <p class="text-payment">{{ $localization("web-interview-please-enter-card-info") }}</p>
        </div>
        <el-radio-group v-model="payWithCard" v-if="user.PaymentMethods.length > 0">
          <el-radio-button :label="true">{{ $localization("web-interview-saved-cards") }} <span style="background: unset" v-if="user.PaymentMethods != null && user.PaymentMethods.length > 0">({{ user.PaymentMethods.length
          }})</span></el-radio-button>
          <el-radio-button :label="false">{{ $localization("web-interview-new-card") }}</el-radio-button>
        </el-radio-group>
        <div class="new-card" v-if="!payWithCard">
          <div class="title">{{ $localization("web-interview-card-info") }}</div>

          <div class="form-item">
            <div class="title">{{ $localization("web-interview-cardholder") }}</div>
            <el-input placeholder="..." v-model="form.CardName"></el-input>
          </div>
          <div class="form-item">
            <div class="title">{{ $localization("web-interview-cardno") }}</div>
            <el-input v-mask="mask.CardNO" placeholder="**** **** **** ****" v-model="form.CardNO"></el-input>
          </div>

          <div class="form-container">
            <div class="form-item">
              <div class="title">{{ $localization("web-interview-expire-date") }}</div>
              <el-input type="text" v-mask="mask.ExpireDate" placeholder="** / **" auto-complete="false" v-model="form.ExpireDate"></el-input>
            </div>
            <div class="form-item">
              <div class="title">{{ $localization("web-interview-cvv") }}</div>
              <el-input type="password" v-mask="mask.CVV" placeholder="***" v-model="form.CVV" autocomplete="off"></el-input>
            </div>
          </div>
          <div class="form-item">
            <el-switch v-model="saveCard" :active-text="$localization('web-interview-save-my-card')" active-color="#54d0c8" :inactive-text="$localization('web-interview-dont-save-my-card')"> </el-switch>
          </div>
          <div class="policy">
            <el-tooltip class="item" effect="dark" :content="$localization('web-interview-please-click')" placement="top">
              <div class="policy-container">
                <el-checkbox v-model="policyControl.policy1" :append-to-body="true"></el-checkbox>
                <div class="text">
                  <span @click="openPolicy(8)">{{ $localization("web-interview-i-red-and-aggree-health-policy") }}</span>
                </div>
              </div>
            </el-tooltip>
            <!-- <el-tooltip class="item" effect="dark" content="Tıklayın" placement="top">
              <div class="policy-container">
                <el-checkbox v-model="policyControl.policy2" :append-to-body="true"></el-checkbox>
                <div class="text">
                  <span @click="openPolicy(2)">Online görüşme açık rıza metni okudum onaylıyorum *</span>
                </div>
              </div>
            </el-tooltip> -->
            <!-- <div class="policy-container no-required">
              <el-checkbox v-model="form.Permission"></el-checkbox>
              <div class="text">
                <span @click="form.Permission = !form.Permission">Görüşme kaydı açık rıza onayı</span>
              </div>
            </div> -->
            <img src="/img/iyzico-banner.svg" alt="Iyzico Banner" height="24" style="margin-top: 10px" />
          </div>
          <el-dialog :visible.sync="policyDialog" width="40%" custom-class="policy-dialog">
            <PolicyDialog :data="policy" @checked="checked" />
          </el-dialog>
          <el-button v-if="form.InterviewType == 2" class="start-pay" @click="payment()" :disabled="onPayment" :loading="onPayment">
            <div class="icon" v-if="!onPayment"></div>
            <span v-if="!onPayment">{{ $localization("web-interview-pay") }}</span>
          </el-button>
          <el-button v-else class="start-pay" @click="beforePayment()" :disabled="onPayment" :loading="onPayment">
            <div class="icon" v-if="!onPayment"></div>
            <span v-if="!onPayment">{{ $localization("web-interview-pay") }}</span>
          </el-button>
          <div id="hidden" style="display: none"></div>
        </div>
        <div class="card-list" v-if="payWithCard">
          <div class="title">{{ $localization("web-interview-my-saved-cards") }}</div>
          <ul class="credit-card-list">
            <li class="item" v-for="(card, index) in user.PaymentMethods" :key="index" :class="{ active: activeCard == card.Id ? true : false }" @click="activeCard = card.Id">
              <div class="icon">
                <i class="el-icon-circle-check"></i>
              </div>
              <div class="card-info">
                <div class="title">
                  {{ card.Title }}
                </div>
                <div class="card-bin">
                  <div class="period-container">
                    <div class="period">
                      {{ card.Bin.slice(0, 4) }}
                    </div>
                    <div class="period">
                      {{ card.Bin.slice(4, 6) }}
                      <div class="icon-period" v-for="i in 2" :key="i"></div>
                    </div>
                    <div class="period">
                      <div class="icon-period" v-for="i in 4" :key="i"></div>
                    </div>
                    <div class="period">
                      <div class="icon-period" v-for="i in 4" :key="i"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-img">
                <img src="/img/cardassocation/mastercard.svg" v-if="card.CardAssociation == 'MASTER_CARD'" alt="" />
                <img src="/img/cardassocation/visa.svg" v-if="card.CardAssociation == 'VISA'" alt="" />
              </div>
            </li>
          </ul>
          <div class="policy">
            <el-tooltip class="item" effect="dark" :content="$localization('web-interview-please-click')" placement="top">
              <div class="policy-container">
                <el-checkbox v-model="policyControl.policy1" :append-to-body="true"></el-checkbox>
                <div class="text">
                  <span @click="openPolicy(8)">{{ $localization("web-interview-i-red-and-aggree-health-policy") }}</span>
                </div>
              </div>
            </el-tooltip>
            <!-- <el-tooltip class="item" effect="dark" content="Tıklayın" placement="top">
              <div class="policy-container">
                <el-checkbox v-model="policyControl.policy2" :append-to-body="true"></el-checkbox>
                <div class="text">
                  <span @click="openPolicy(2)">Online görüşme açık rıza metni okudum onaylıyorum *</span>
                </div>
              </div>
            </el-tooltip> -->

            <!-- <div class="policy-container no-required">
              <el-checkbox v-model="form.Permission"></el-checkbox>
              <div class="text">
                <span @click="form.Permission = !form.Permission">Görüşme kaydı açık rıza onayı</span>
              </div>
            </div> -->
            <img src="/img/iyzico-banner.svg" alt="Iyzico Banner" height="24" style="margin-top: 10px" />
          </div>
          <el-dialog :visible.sync="policyDialog" width="40%" custom-class="policy-dialog">
            <PolicyDialog :data="policy" @checked="checked" />
          </el-dialog>
          <el-button v-if="form.InterviewType == 2" class="start-pay" @click="payment()" :disabled="onPayment" :loading="onPayment">
            <div class="icon" v-if="!onPayment"></div>
            <span v-if="!onPayment">{{ $localization("web-interview-pay") }}</span>
          </el-button>
          <el-button v-else class="start-pay" @click="beforePayment()" :disabled="onPayment" :loading="onPayment">
            <div class="icon" v-if="!onPayment"></div>
            <span v-if="!onPayment">{{ $localization("web-interview-pay") }}</span>
          </el-button>
        </div>
      </div>
      <div class="card-details">
        <div class="top">
          <div class="header">
            <div class="title">{{ $localization("web-interview-details") }}</div>
            <el-button type="text"> </el-button>
          </div>
          <CreditCard :data="form" />
        </div>
        <div class="bottom">
          <div class="item">
            <div class="title">{{ $localization("web-interview-meet-time") }}</div>
            <div class="desc" v-if="form.InterviewType">{{ $localization("web-enum-interviewtype-" + getEnums.find((x) => x.Title ==
              "InterviewType").Enums.find((x) => x.Key == form.InterviewType).Value.toLowerCase()) }}</div>
            <div class="desc" v-else>-</div>
          </div>
          <div class="item" v-if="form.InterviewType == 2">
            <div class="title">{{ $localization("web-interview-meet-time") }}</div>
            <div class="desc" v-if="date">{{ $moment(date).format("Do.MM.YYYY") }}</div>
          </div>
          <div class="item" v-if="form.InterviewType == 2">
            <div class="title">{{ $localization("web-interview-meet-date") }}</div>
            <!-- <div class="desc" v-if="form.AppointmentHour">{{ $moment(form.AppointmentHour).format("Do.MM.YYYY h:mm") }}</div> -->
            <div class="desc" v-if="hour">{{ hour }}</div>
          </div>

          <div class="item">
            <div class="title">{{ $localization("web-interview-card-type") }}</div>
            <div class="desc">{{ $localization("web-interview-visa") }}</div>
          </div>
          <div class="item">
            <div class="title">{{ $localization("web-interview-cardholder") }}</div>
            <div class="desc">{{ form.CardName }}</div>
          </div>
          <div class="item">
            <div class="title">{{ $localization("web-interview-cardno") }}</div>
            <div class="desc">{{ form.CardNO }}</div>
          </div>
          <div class="item">
            <div class="title">{{ $localization("web-interview-expire-date") }}</div>
            <div class="desc">{{ form.ExpireDate }}</div>
          </div>

          <div class="item">
            <div class="title">{{ $localization("web-interview-price") }}</div>
            <div class="desc">{{ (appointmentPrice / 1.08).toFixed(2) }} {{ $store.state.auth.language == "tr" ? "TL" : "USD" }}</div>
          </div>
          <div class="item">
            <div class="title">{{ $localization("web-interview-tax") }}</div>
            <div class="desc">{{ (appointmentPrice - (appointmentPrice / 1.08).toFixed(2)).toFixed(2) }} {{ $store.state.auth.language == "tr" ? "TL" :
              "USD" }}</div>
          </div>
          <div class="item">
            <div class="title" style="color: #54d0c8">{{ $localization("web-interview-total-price") }}</div>
            <div class="desc" style="color: #54d0c8">{{ appointmentPrice }} {{ $store.state.auth.language == "tr" ? "TL" : "USD" }}</div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="mernisCheck" :width="screenWidth > 991 ? '40%' : '90%'" class="check-mernis" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="dialog">
        <!-- <div class="close" @click="mernisCheck = false">
          <i class="icon-close"></i>
        </div> -->
        <div class="form">
          <div class="title">{{ $localization("web-interview-mernis-not-confirmed") }}</div>

          <div>
            <div class="text">{{ $localization("web-interview-form-name") }}</div>
            <el-input v-model="mernisForm.FirstName" class="tc" placeholder="..."></el-input>
            <span class="error">{{ $errorMessage("FirstName", ValidationErrors) }}</span>
          </div>
          <div>
            <div class="text">{{ $localization("web-interview-form-surname") }}</div>
            <el-input v-model="mernisForm.LastName" class="tc" placeholder="..."></el-input>
            <span class="error">{{ $errorMessage("LastName", ValidationErrors) }}</span>
          </div>
          <div>
            <div class="text">{{ $localization("web-interview-form-identity-number") }}</div>
            <el-input maxlength="11" v-mask="mask.IdentityNumber" placeholder="..." class="tc" v-model="mernisForm.IdentityNumber"></el-input>
            <span class="error">{{ $errorMessage("IdentityNumber", ValidationErrors) }}</span>
          </div>
          <div>
            <div class="text">{{ $localization("web-interview-form-birthdate") }}</div>
            <el-date-picker style="width: 100%" v-model="mernisForm.Birthdate" format="dd.MM.yyyy" class="tc" type="date" :placeholder="$localization('web-interview-form-select')" default-value="1980-01-01" prefix-icon="none">
            </el-date-picker>
            <span class="error">{{ $errorMessage("Birthdate", ValidationErrors) }}</span>
          </div>
          <div class="buttons">
            <el-button class="login-btn" @click="checkMernisRegistration">{{ $localization("web-interview-form-save") }}</el-button>
            <el-button class="cancel-btn" @click="logout()">{{ $localization("web-interview-log-out") }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="isClientOkDialog" :width="screenWidth > 991 ? '40%' : '90%'" class="check-mernis" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="dialog">
        <div class="form">
          <div class="title">{{ $localization("web-interview-cam-mic-permission") }}</div>
          <div class="text">{{ $localization("web-interview-cam-mic-permission-description") }}</div>
          <div class="buttons">
            <el-button class="login-btn" @click="payment()">{{ $localization("web-interview-confirm") }}</el-button>
            <el-button class="cancel-btn" @click="isClientOkDialog = false">{{ $localization("web-interview-cancel") }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CreditCard from "../../components/CreditCard.vue";
import PolicyDialog from "../../components/PolicyDialog.vue";
export default {
  components: {
    CreditCard,
    PolicyDialog,
  },
  data() {
    return {
      currentMoneyType: null,
      countryList: [],
      allSpecialities: [],
      ValidationErrors: [],
      doctorsList: [],
      enableDoctorHours: [],
      screenWidth: 0,
      meetingType: 2,
      mernisCheck: false,
      isClientOkDialog: false,
      isClientOk: false,
      SpecialityId: null,
      date: null,
      hour: null,
      roomId: null,
      mernisForm: {
        FirstName: null,
        LastName: null,
        IdentityNumber: null,
        Birthdate: null,
      },
      test: null,
      policyDialog: false,
      policy: null,
      policyControl: {
        policy1: false,
        policy2: false,
      },
      activeCard: null,
      onPayment: false,
      payWithCard: false,
      saveCard: false,
      mask: {
        CardNO: "#### #### #### ####",
        ExpireDate: "##/##",
        CVV: "###",
        Identity: "###########",
      },
      form: {
        CardName: "",
        CardNO: "",
        CVV: "",
        ExpireDate: "",
        PatientId: null,
        InterviewType: 2,
        DoctorId: null,
        AppointmentDate: null,
        AppointmentHour: null,
      },
      appointmentPrice: null,
      discount: null,
      pickerOptions: {
        disabledDate(date) {
          const today = new Date();
          return date > new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14) || date < new Date(today.getFullYear(), today.getMonth(), today.getDate());
        },
        firstDayOfWeek: 1,
      },
    };
  },

  computed: {
    appointmentList() {
      return this.$store.getters.getAppointments;
    },
    user() {
      return this.$store.getters.getUser;
    },
    children() {
      return this.$store.getters.getChildrenList.filter((x) => x.ParentId == this.user.ID);
    },
    patient() {
      var patient = this.children.concat(this.user);
      return patient;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
  },
  async created() {
    await this.checkAppointment();
  },
  async beforeMount() {
    window.jQuery(document).ready(function () {
      window.jQuery(".slider").owlCarousel({
        items: 1,
        loop: true,
        margin: 30,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
      });
    });
    if (this.user.PaymentMethods.length > 0) {
      this.activeCard = this.user.PaymentMethods[0].Id;
    }
    var res = await this.$client.post("/Country/List", { SearchTerm: "", PageSize: 99999, Page: 1 });
    if (res.data.HasError) {
      this.$message.error(res.data.Message);
      setTimeout(async () => {
        this.$router.push("/");
      }, 2000);
    } else {
      this.countryList = res.data.Data.Items;
    }
    await this.$store.dispatch("getChildrenList", this.filter);
    await this.getAllSpecialities();
    //await this.getPrice();
  },
  async mounted() {
    await this.isMernisOk();
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
    if (this.$route.query.expired) {
      this.$alert(this.$localization("web-interview-we-are-sorry-that-we-could-not-meet-you-with-our-consultant"), this.$localization("web-interview-information"), {
        dangerouslyUseHTMLString: true,
        confirmButtonClass: "pop-confirm-class",
      });
    }
    if (this.$route.params.id != null) {
      var res = await this.$client.post("/Appointment/GetAppointmentPaymentInfo", { AppointmentId: this.$route.params.id });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        if (res.data.Data.PaymentInfo.PaymentCompleted) {
          if (res.data.Data.InterviewInfo.InterviewType == 2) {
            this.$alert(this.$localization("web-interview-you-are-redirecting-my-meets-page"), this.$localization("web-interview-payment-succesfuly"), {
              confirmButtonText: this.$localization("web-interview-ok"),
              type: "success",
              confirmButtonClass: "pop-confirm-class",
              center: true,
            })
              .then(() => {
                this.$message({
                  type: "success",
                  message: this.$localization("web-interview-you-are-redirecting-my-meets-page"),
                });
                this.$router.push("/kullanici");
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: this.$localization("web-interview-you-are-redirecting-my-meets-page"),
                });
                this.$router.push("/kullanici");
              });
          } else {
            this.$alert(this.$localization("web-interview-you-are-redirecting-meet-room"), this.$localization("web-interview-payment-succesfuly"), {
              confirmButtonText: this.$localization("web-interview-ok"),
              type: "success",
              confirmButtonClass: "pop-confirm-class",
              center: true,
            })
              .then(() => {
                this.$message({
                  type: "success",
                  message: this.$localization("web-interview-you-are-redirecting-meet-room"),
                });
                this.$router.push("/kullanici/gorusme-odasi/" + this.$route.params.id);
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: this.$localization("web-interview-you-are-redirecting-meet-room"),
                });
                this.$router.push("/kullanici/gorusme-odasi/" + this.$route.params.id);
              });
          }
        } else {
          this.$alert(res.data.Data.PaymentInfo.ErrorMessage, this.$localization("web-interview-payment-error"), {
            confirmButtonText: this.$localization("web-interview-ok"),
            type: "error",
            center: true,
            showClose: false,
            distinguishCancelAndClose: true,
          });
        }
      }
    }
  },
  methods: {
    async getPrice() {
      var price = this.doctorsList.find(doctor => doctor.ID === this.form.DoctorId).AppointmentPrice
      var res = await this.$client.post("/Appointment/GetAppointmentPrice", { AppointmentPrice: price });
      if (!res.data.HasError) {
        this.appointmentPrice = res.data.Data;
      }
    },
    setMeetingType(type) {
      this.ValidationErrors = [];
      this.clearForm();

      if (type == 1) {
        this.meetingType = 1;
        this.form.InterviewType = 1;
      } else if (type == 2) {
        this.meetingType = 2;
        this.form.InterviewType = 2;
      }
      //  else if (type == 3) {
      //   this.meetingType = 3;
      // }
    },
    clearForm() {
      this.form.InterviewType = null;
      this.SpecialityId = null;
      this.form.DoctorId = null;
      this.date = null;
      this.hour = null;
    },
    async getAllSpecialities() {
      await this.$client.post("/Speciality/List", { SearchTerm: null, Page: 1, PageSize: 1000 }).then((res) => {
        this.allSpecialities = res.data.Data.Items.filter((x) => x.IsActive);
      });
    },
    async setDoctors() {
      this.hour = null;
      this.date = null;
      this.appointmentPrice = null;
      this.form.DoctorId = null;
      await this.$client.post("/Speciality/DoctorList", { SpecialityId: this.SpecialityId }).then((res) => {
        this.doctorsList = res.data.Data.Items;
      });
    },
    async setEnableDoctorHour() {
      this.hour = null;
      await this.$client.post("/Speciality/CheckAvailableTimes", { DoctorId: this.form.DoctorId, AppointmentDate: this.date }).then((res) => {
        this.enableDoctorHours = res.data.Data.Items;
      });
    },
    resetDateHourAndPrice() {
      this.date = null;
      this.hour = null;
      this.getPrice();
    },
    updateTime(dateString, newTime) {
      const date = new Date(dateString);
      const timeParts = newTime.split(":");
      const hour = parseInt(timeParts[0], 10);
      const minute = parseInt(timeParts[1], 10);
      date.setHours(hour);
      date.setMinutes(minute);
      this.form.AppointmentDate = date;
    },
    async logout() {
      await this.$client.post("/Account/SignOut");
      localStorage.removeItem("token");
      this.$store.state.auth.user = null;
      this.$router.push({ name: "Login" });
    },
    async isMernisOk() {
      if (this.user.IsMernisVerified != true) {
        this.mernisCheck = true;
      }
    },
    async checkMernisRegistration() {
      var res = await this.$client.post("/Account/CheckMernisRegistration", this.mernisForm);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
      } else {
        this.$message.success(res.data.Message);
        this.mernisCheck = false;
        await this.$store.dispatch("getUser");
      }
    },
    async checkAppointment() {
      await this.getAppointmentList();
      var appointment = this.appointmentList.Appointments.filter((item) => item.Status == 1 || (item.Status == 2 && item.DoctorUser == null))[0];
      if (appointment) {
        this.$router.push("/kullanici/gorusme-odasi/" + appointment.ID);
      }
    },
    async getAppointmentList() {
      await this.$store.dispatch("getListUserAppointments", {
        PageSize: 100,
        SearchTerm: null,
        Status: null,
        Page: 1,
      });
    },
    checked(number, type) {
      var switchChecked = type == 1;
      switch (number) {
        case 8:
          this.policyControl.policy1 = switchChecked;
          break;
        case 2:
          this.policyControl.policy2 = switchChecked;
          break;
      }
      this.policyDialog = false;
    },
    async openPolicy(type) {
      var res = await this.$client.post("/Definition/GetPolicyByType", {
        Type: type,
      });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.policy = res.data.Data.Policy;
        this.policyDialog = true;
      }
    },

    async payment() {
      this.ValidationErrors = [];
      this.isClientOkDialog = false;
      this.isClientOk = true;
      if (!this.policyControl.policy1) {
        this.$notify({
          title: this.$localization("web-interview-warning"),
          message: this.$localization("web-interview-you-must-aggree-policies"),
          type: "warning",
          duration: 2000,
        });
        this.loading = false;
        return;
      }
      if ((this.SpecialityId == null || this.SpecialityId == "") && (this.form.InterviewType == 2 || this.form.InterviewType == 3)) {
        this.$message.error(this.$localization("web-interview-please-select-speciality"));
        this.ValidationErrors.push({
          Key: "Speciality",
          Value: this.$localization("web-interview-please-fill-field"),
        });
        this.loading = false;
        return;
      }
      if (this.form.InterviewType == 2) {
        if (this.date == null || this.date == "") {
          this.$message.error(this.$localization("web-interview-please-select-date"));
          this.ValidationErrors.push({
            Key: "InterviewDate",
            Value: this.$localization("web-interview-please-fill-field"),
          });
          this.loading = false;
          return;
        } else {
          if (this.hour == null || this.hour == "") {
            this.$message.error(this.$localization("web-interview-please-select-hour"));
            this.ValidationErrors.push({
              Key: "InterviewHour",
              Value: this.$localization("web-interview-please-fill-field"),
            });
            this.loading = false;
            return;
          } else {
            this.updateTime(this.date, this.hour);
          }
        }
      }

      this.onPayment = true;
      var expireArray = this.form.ExpireDate.split("/");

      if (!this.payWithCard) {
        let paymentObj = {
          CardNo: this.form.CardNO.replace(/\s/g, ""),
          CardHolder: this.form.CardName,
          ExpireYear: expireArray[1],
          ExpireMonth: expireArray[0],
          Cvc: this.form.CVV,
          SaveCard: this.saveCard,
          AppointmentRequestFrom: 0,
          PatientId: this.form.PatientId,
          DoctorId: this.form.DoctorId,
          AppointmentDate: this.form.AppointmentDate,
          InterviewType: this.form.InterviewType,
          Permission: this.form.Permission,
        };
        var res = await this.$client.post("/Appointment/Start", paymentObj);
        if (res.data.HasError) {
          this.ValidationErrors = res.data.ValidationErrors;
          this.$message.error(res.data.Message);
        } else {
          window.jQuery("#hidden").html(res.data.Data.ThreedHtml);
          window.jQuery("#hidden").find("form").submit();
        }
      } else {
        let res = await this.$client.post("/Appointment/StartWithCard", {
          PaymentMethodId: this.activeCard,
          AppointmentRequestFrom: 0,
          PatientId: this.form.PatientId,
          DoctorId: this.form.DoctorId,
          AppointmentDate: this.form.AppointmentDate,
          InterviewType: this.form.InterviewType,
          Permission: this.form.Permission,
        });
        if (res.data.HasError) {
          this.ValidationErrors = res.data.ValidationErrors;
          this.$message.error(res.data.Message);
        } else {
          if (this.form.InterviewType == 2) {
            this.$confirm(this.$localization("web-interview-appointment-created"), this.$localization("web-interview-payment-succesfuly"), {
              confirmButtonText: this.$localization("web-interview-ok"),
              confirmButtonClass: "pop-confirm-class",
              type: "success",
              center: true,
              showClose: false,
              distinguishCancelAndClose: true,
            })
              .then(() => {
                this.$message({
                  type: "success",
                  message: this.$localization("web-interview-you-are-redirecting-my-meets-page"),
                });
                this.$router.push("/kullanici");
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: this.$localization("web-interview-you-are-redirecting-my-meets-page"),
                });
                this.$router.push("/kullanici");
              });
          } else {
            this.isClientOkDialog == true;
            this.roomId = res.data.Data.Appointment.ID;
            this.$confirm(this.$localization("web-interview-you-are-redirecting-meet-room"), this.$localization("web-interview-payment-succesfuly"), {
              confirmButtonText: this.$localization("web-interview-ok"),
              confirmButtonClass: "pop-confirm-class",
              type: "success",
              center: true,
              showClose: false,
              distinguishCancelAndClose: true,
            })
              .then(() => {
                this.$message({
                  type: "success",
                  message: this.$localization("web-interview-you-are-redirecting-meet-room"),
                });
                this.$router.push("/kullanici/gorusme-odasi/" + res.data.Data.Appointment.ID);
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: this.$localization("web-interview-you-are-redirecting-meet-room"),
                });
                this.$router.push("/kullanici/gorusme-odasi/" + res.data.Data.Appointment.ID);
              });
          }
        }
      }
      this.onPayment = false;

      // this.$alert('Görüşme Ekranına Yönlendiriliyorsunuz', 'Ödemeniz başarıyla alındı!', {
      //   confirmButtonText: 'Tamam',
      //   type: 'success',
      //   center: true,
      //   showClose:false,
      //   distinguishCancelAndClose:true,
      // }).then(() => {
      //   this.$message({
      //     type: 'success',
      //     message: 'Görüşme ekranına yönlendiriliyorsunuz'
      //   });
      //   this.$router.push({
      //     name: 'GorusmeOdasi'
      //   });
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: 'Görüşme ekranına yönlendiriliyorsunuz'
      //   });
      //   this.$router.push({
      //     name: 'GorusmeOdasi'
      //   });
      // });
    },
    beforePayment() {
      if (this.isClientOk == false) {
        this.isClientOkDialog = true;
      } else {
        this.payment();
      }
    },
    notOk() {
      this.isClientOkDialog = false;
      this.isClientOk = false;
    },
  },
  watch: {
    "$store.state.auth.language": function () {
      this.getPrice();
    },
  },
};
</script>

<style lang="less">
//#region 16/06/2023 HS !!! mobil için yeni boyut eklendi
.el-message-box {
  @media screen and (max-width: 500px) {
    width: 90%;
  }
}
</style>
