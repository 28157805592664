export default {
    setTransactionList(state, data) {
        state.list = data;
    },
    setTransactionValidation(state, data) {
        state.validation = data;
    },
    setTransactionTotalCount(state, data) {
        state.result.TotalCount = data;
      },
      setTransactionPageCount(state, data) {
        state.result.PageCount = data;
      },
}