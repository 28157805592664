export default {
    setMessagesMB(state, data) {
        state.messages = data
    },
    setActiveMessageMB(state, data) {
        state.activeMessage = data
    },
    setDoctorsMB(state, data) {
        state.doctors = data
    },
    setNursesMB(state, data) {
        state.nurses = data
    },
}