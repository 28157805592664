var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"rootNode",staticClass:"peer",attrs:{"data-component":"Me"}},[(_vm.connected)?_c('div',[_c('div',{staticClass:"controls"},[_c('div',{staticClass:"button mic",class:_vm.micState,on:{"click":_vm.toggleMicState}}),_c('div',{staticClass:"button webcam",class:{
          webcamState: _vm.webcamState,
          disabled: _vm.me.webcamInProgress || _vm.me.shareInProgress
        },on:{"click":_vm.toggleWebcam}}),_c('div',{staticClass:"button change-webcam",class:{
          changeWebcamState: _vm.changeWebcamState,
          disabled: _vm.me.webcamInProgress || _vm.me.shareInProgress
        },on:{"click":_vm.changeWebcam}}),_c('div',{staticClass:"button share",class:{
          shareState: _vm.shareState,
          disabled: _vm.me.shareInProgress || _vm.me.webcamInProgress
        },on:{"click":_vm.toggleShare}})])]):_vm._e(),_c('peer-view',{attrs:{"is-me":true,"peer":_vm.me,"audio-producer-id":_vm.audioProducer ? _vm.audioProducer.id : null,"video-producer-id":_vm.videoProducer ? _vm.videoProducer.id : null,"audio-rtp-parameters":_vm.audioProducer ? _vm.audioProducer.rtpParameters : null,"video-rtp-parameters":_vm.videoProducer ? _vm.videoProducer.rtpParameters : null,"audio-track":_vm.audioProducer ? _vm.audioProducer.track : null,"video-track":_vm.videoProducer ? _vm.videoProducer.track : null,"video-visible":Boolean(_vm.videoProducer) && !_vm.videoProducer.paused,"audio-codec":_vm.audioProducer ? _vm.audioProducer.codec : null,"video-codec":_vm.videoProducer ? _vm.videoProducer.codec : null,"audio-score":_vm.audioProducer ? _vm.audioProducer.score : null,"video-score":_vm.videoProducer ? _vm.videoProducer.score : null,"face-detection":_vm.faceDetection},on:{"onChangeDisplayName":_vm.changeDisplayName,"onChangeMaxSendingSpatialLayer":_vm.changeMaxSendingSpatialLayer,"onStatsClick":_vm.setStatsPeerId}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }