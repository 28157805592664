export default {
  setSupportRequestList(state, data) {
    state.list = data;
  },
  setSupportRequestTotalCount(state, data) {
    state.result.TotalCount = data;
  },
  setMultipleSelect(state, data) {
    state.multiSelect = data;
  },
};
