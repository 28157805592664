<template>
  <div class="container">
    <div class="title">{{data.Title}}</div>
    <div class="details" v-html="data.Details">
    </div>
    <div class="button-groups" >
      <el-button class="danger" @click="checked(data.Type, 0)"  v-if="data.Type!=5"> {{ $localization('web-policy-dialog-cancel') }} </el-button>
      <el-button class="danger" @click="checked(data.Type, 0)" v-else> {{ $localization('web-policy-dialog-close') }} </el-button>
      <el-button @click="checked(data.Type, 1)" v-if="data.Type!=5">
        <i class="icon-save"></i>
        {{ $localization('web-policy-dialog-accept') }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
    props: ['data'],
    name:"PolicyDialog",
    methods: {
        checked(number, type){
            this.$emit('checked', number,type)
        }
    }
};
</script>

<style></style>
