export default {
    setNoteList(state, data) {
        state.list = data;
    },
    setNoteValidation(state, data) {
        state.validation = data;
    },
    setNoteTotalCount(state, data) {
        state.result.TotalCount = data;
      },
      setNotePageCount(state, data) {
        state.result.PageCount = data;
      },
}