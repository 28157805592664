export default {
    setPolicyList(state, data) {
        state.list = data;
    },
    setPolicyValidation(state, data) {
        state.validation = data;
    },
    setPolicyTotalCount(state, data) {
        state.result.TotalCount = data;
    },
    setPolicyPageCount(state, data) {
        state.result.PageCount = data;
    },
 

}