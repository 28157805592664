import { client } from "@/helpers/axiosHelpers"
import { Message } from 'element-ui';

export default {
    async getDocumentCategories(context, payload) {
        var res = await client.post("/FileManager/ListDocumentCategories", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            context.commit("setDocumentCategories", res.data.Data);
        }
    },
    async getDocuments(context, payload) {
        var res = await client.post("/FileManager/ListDocuments", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            context.commit("setDocuments", res.data.Data);
        }
    },
    async deleteDocument(context, payload) {
        var res = await client.post("/FileManager/DeleteDocument", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            context.commit("deleteDocument", payload.DocumentItemId);
        }
        return res.data;
    },
    async generatePreSignedUrl(context, payload) {
        var res = await client.post("/Account/GeneratePreSignedUrl", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        }
        return res.data;
    },
    async deleteDocumentCategory(context, payload) {
        var res = await client.post("/FileManager/DeleteDocumentCategory", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            context.commit("deleteDocumentCategory", payload.Id);
        }
    },
    async addUpdateDocumentCategory(context, payload) {
        var res = await client.post("/FileManager/AddUpdateDocumentCategory", payload);
        context.commit('setValidation', res.data.ValidationErrors);

        if (res.data.HasError) {
            Message.error(res.data.Message);
        }else {
            //context.commit("deleteDocumentCategory", payload.Id);
        }
        return res.data;
    },
    async addDocument(context, payload) {
        var res = await client.post("/FileManager/UploadDocuments", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            //context.commit("deleteDocumentCategory", payload.Id);
        }
        return res.data;
    },
}