<template>
  <div data-component="Peers" class="peer-container">
    <Me :room-client="roomClient" />

    <transition v-for="peer in peers" :key="peer.id" appear>
      <Peer :peer="peer" :room-client="roomClient" />
    </transition>

    <div class="peer flush" v-if="peers.length == 0">
      <img src="../../../assets/img/spinner.png" alt="" />
      <p>{{ $localization('web-peers-waiting') }}</p>
    </div>
  </div>
</template>

<script>
import Peer from "./Peer";
import Me from "./Me";

export default {
  components: { Peer, Me },
  props: {
    roomClient: {
      type: Object,
      required: true,
    },
  },
  computed: {
    peers() {
      return this.$store.state.room.peers.filter((x) => x.consumers != null && x.displayName != "recording" && x.consumers.length > 0);
    },
    activeSpeakerId() {
      return this.$store.state.room.room.activeSpeakerId;
    },
    me() {
      return this.$store.state.room.me;
    },
    amActiveSpeaker() {
      return this.$store.state.room.me.id === this.$store.state.room.room.activeSpeakerId;
    },
    room() {
      return this.$store.state.room.room;
    },
  },
};
</script>
