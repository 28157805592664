<template>
  <div id="nurseHome">
    <div class="title">Ana Sayfa</div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Görüşmelerim</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="allcard">
      <div class="card" v-for="(item, index) in chartInfo" :key="index">
        <div class="card-left">
          <div class="card-tag">{{ item.Title }}</div>
          <div class="card-desc">{{ item.Description }}</div>
          <el-row type="flex" class="row-bg" justify="space-between" align="middle">
            <el-col :span="12">
              <div class="card-total-number">{{ item.Count }}</div>
            </el-col>
            <el-col :span="12">
              <el-row type="flex" align="middle" class="row-bg" justify="end">
                <i class="icon-trade-up" :class="{ plus: item.IncreaseRate >= 0 ? true : false }"></i>
                <div class="card-percent" :class="{ plus: item.IncreaseRate >= 0 ? true : false }">{{ item.IncreaseRate }}</div>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <div class="bar-container today-meet">
          <div class="one-day" v-for="(stat, index) in item.Statistics" :key="index">
            <div class="stick">
              <div class="bar" :class="{ active: stat.Today }" :style="{ height: stat.Percentage + 'px' }"></div>
            </div>
            <div class="text">{{ $moment(stat.CalculatedDateRangeMax).format("dddd")[0] }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-general">
      <div class="search-status" v-if="activeTable == 2">
        <div class="status">
          Göster
          <el-select v-model="pastFilter.PageSize" placeholder="Seçiniz...">
            <el-option v-for="(item, index) in pagesize" :key="index" :label="item" :value="item"> </el-option>
          </el-select>
          <el-select v-model="pastFilter.Status" placeholder="Seçiniz..." clearable @clear="clearStatus">
            <el-option v-for="(item, index) in getEnums.find((x) => x.Title == 'AppointmentStatus').Enums" :key="index" :label="item.DisplayName"
              :value="item.Key"> </el-option>
          </el-select>
        </div>
        <div class="search">
          <el-input placeholder="Kayıt Ara" v-model="pastFilter.SearchTerm" clearable>
            <i slot="prefix" class="el-input__icon el-icon-search" style="color: #8697a8; font-weight: bold"></i>
          </el-input>
        </div>
      </div>
      <el-row class="buttons">
        <el-button class="meetButton" :class="{ active: activeTable == 1 ? true : false }" @click="activeTable = 1">Bekleyen Görüşmeler</el-button>
        <el-button class="meetButton" :class="{ active: activeTable == 2 ? true : false }" @click="activeTable = 2">Yaptığım Görüşmeler</el-button>
      </el-row>
      <div class="table">
        <el-table :data="activeTable == 1 ? $store.state.appointments.pendingAppointments : pastAppoinments.Appointments" style="width: 100%">
          <template slot="empty">
            <div class="lottie-container">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
            </div>
          </template>
          <el-table-column label="Id" width="120">
            <template slot-scope="scope"> #{{ scope.row.AppointmentNo }} </template>
          </el-table-column>
          <el-table-column label="HASTA" width="250">
            <template slot-scope="scope">
              <div class="user-info">
                <div class="user-avatar">
                  <div class="short">{{ scope.row.User.Title[0] }}</div>
                </div>
                <div class="user-name">
                  <span> {{ scope.row.User.Title }} </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="diagnosis" label="ŞİKAYET" v-if="activeTable != 2">
            <template slot-scope="scope">
              {{ scope.row.PatientDiagnosis.Title != null ? scope.row.PatientDiagnosis.Title : "..." }}
            </template>
          </el-table-column>
          <el-table-column prop="diagnosis" label="TANI" v-if="activeTable != 1" width="180">
            <template slot-scope="scope">
              {{ scope.row.PatientDiagnosis.DoctorDiagnosis != null ? scope.row.PatientDiagnosis.DoctorDiagnosis : "..." }}
            </template>
          </el-table-column>
          <el-table-column label="PLATFORM" width="200">
            <template slot-scope="scope">
              {{ getEnumsDisplay("DevicePlatform", scope.row.AppointmentRequestFrom) }}
            </template>
          </el-table-column>
          <el-table-column prop="price" label="ÜCRET" v-if="activeTable == 2">
            <template slot-scope="scope"> {{ scope.row.PaymentInfo.PaidPrice }}TL </template>
          </el-table-column>
          <el-table-column prop="WaitingTime" label="BEKLENEN SÜRE" v-else width="180">
            <template slot-scope="scope"> {{ scope.row.PendingDurationInMinutes }} dakika </template>
          </el-table-column>
          <el-table-column prop="status" label="DURUM" width="200">
            <template slot-scope="scope">
              <div class="color-dot">
                <div class="dot" :class="{ yellow: scope.row.Status == 1, red: scope.row.Status == 4, green: scope.row.Status == 3 }"></div>
                {{ getEnumsDisplay("AppointmentStatus", scope.row.Status) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="StarRating" label="DEĞERLENDİRME" v-if="activeTable == 2" width="180">
            <template slot-scope="scope">
              <el-rate v-if="scope.row.RateInfo.IsRated" v-model="scope.row.RateInfo.Rate" disabled text-color="#ff9900"
                score-template="{value} points"> </el-rate>
              <span v-else>Değerlendirilmemiş</span>
            </template>
          </el-table-column>
          <el-table-column :label="activeTable == 1 ? 'TALEP ZAMANI' : 'TARİH'" width="180">
            <template slot-scope="scope">
              {{ $moment(scope.row.CreatedDateTime).format("DD.MM.YYYY") }}
            </template>
          </el-table-column>

          <el-table-column label="GÖRÜŞME DURUMU" v-if="activeTable == 1" :key="activeTable" width="250">
            <template slot-scope="scope">
              <el-button class="meeting-button" :loading="loading == scope.row.ID" :disabled="scope.$index != 0"
                :class="{ emergency: scope.$index == 0 }" type="text" @click="joinAppointment(scope.row.ID)"> <i v-if="loading == false"
                  class="el-icon-video-camera"></i> {{ scope.row.DoctorUser != null && scope.row.DoctorUser.Id == user.ID ? "GÖRÜŞMEYE DEVAM ET" :
                    "GÖRÜŞMEYE KATIL" }}</el-button>
            </template>
          </el-table-column>
          <el-table-column width="120" align="center" v-if="activeTable == 2" :key="activeTable">
            <template slot-scope="scope">
              <el-button type="text"><router-link :to="'nurse/gorusme-detay/' + scope.row.ID"><i class="icon-dots"></i></router-link></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination" v-if="activeTable == 2">
        <div class="info">{{ pastFilter.PageSize * pastFilter.Page - 10 }}-{{ pastFilter.PageSize * pastFilter.Page > pastAppoinments.TotalCount ?
          pastAppoinments.TotalCount : pastFilter.PageSize * pastFilter.Page }} {{ $localization('web-pagination-info-text') }} {{
    pastAppoinments.TotalCount }}</div>
        <el-pagination background :current-page.sync="pastFilter.Page" :page-size="pastFilter.PageSize" layout="prev, pager, next"
          :total="pastAppoinments.TotalCount"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      loading: false,
      chartInfo: [],
      activeTable: 1,
      pagesize: [10, 20, 30, 40],
      pastAppoinments: [],
      pastFilter: {
        PageSize: 10,
        Page: 1,
        Status: null,
        SearchTerm: null,
        PastAppointments: true,
      },
    };
  },
  async created() {
    await this.$store.dispatch("getListUserAppointments", this.$store.state.appointments.filter);
    var res = await this.$client.post("/Appointment/List", this.pastFilter);
    if (res.data.HasError) {
      this.$message.error(res.data.Message);
    } else {
      this.pastAppoinments = res.data.Data;
    }

    var stat = await this.$client.post("/Doctor/AppointmentStatistics", {});
    if (stat.data.HasError) {
      this.$message.error(res.data.Message);
    } else {
      this.chartInfo = stat.data.Data.Statistics;
    }
  },

  methods: {
    clearStatus() {
      this.pastFilter.Status = null;
    },
    async joinAppointment(scope) {
      window.doNotThrowMe = true;
      await this.$client.post("/Appointment/ShutdownOtherWindows", {});
      setTimeout(() => {
        window.doNotThrowMe = false;
      }, 1000);

      this.loading = scope;
      var res = await this.$client.post("/Doctor/JoinAppointment", { AppointmentId: scope });
      if (!res.data.HasError) {
        this.$router.push("/hemsire/gorusme-odasi/" + scope);

        //#region HS 23/05/2023 görüşme başlandığında alarmın durdurulması için method
        this.$emit('stopAlarm');
        //#endregion

      } else {
        this.$message.error(res.data.Message);
      }
      this.loading = false;
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    getEnums() {
      return this.$store.state.auth.enums;
    },
    getAppointments() {
      return this.$store.state.appointments.appointments.Appointments;
    },
    filter() {
      return this.$store.state.appointments.filter;
    },
  },
  watch: {
    pastFilter: {
      handler: async function () {
        var res = await this.$client.post("/Appointment/List", this.pastFilter);
        if (res.data.HasError) {
          this.$message.error(res.data.Message);
        } else {
          this.pastAppoinments = res.data.Data;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="less">
#nurseHome {
  padding: 42px 30px;

  .user-info {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    .user-avatar {
      flex-shrink: 0;
      width: 30px;
      height: 30px;

      .short {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: orange;
        height: 100%;
        border-radius: 50%;
        color: white;
        font-weight: bold;
        font-size: 13px;
      }
    }
  }

  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;
  }

  .allcard {
    display: flex;
    flex-direction: row;
    gap: 30px;

    @media screen and (max-width: 1307px) {
      & {
        flex-direction: column;
      }

      &>.card {
        width: 100%;
      }
    }

    .el-row {
      justify-content: flex-start;
    }
  }

  .card {
    width: 33.333%;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    background-color: white;
    min-height: 180px;
    border-radius: 10px;
    box-shadow: 0px 32px 32px rgba(169, 194, 209, 0.1), 0px 8px 16px rgba(169, 194, 209, 0.05);
    padding: 20px 20px 20px 40px;

    .card-left {
      height: 142px;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      gap: 10px;

      i {
        transform: rotate(180deg);
        background-color: #ff3d57;

        &.plus {
          background-color: #09b66d;
          transform: rotate(360deg);
        }
      }
    }
  }

  .card-tag {
    font-size: 20px;
    color: #44566c;
    font-weight: 700;
  }

  .card-desc {
    font-size: 15px;
    color: #44566c;
    font-weight: 400;
  }

  .card-total-number {
    font-size: 24px;
    color: #44566c;
    font-weight: 500;
  }

  .card-percent {
    &.plus {
      background-color: #09b66d;
    }

    background-color: #ff3d57;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 5px;

    height: 18px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: white;
  }

  .one-day {
    padding-top: 10px;
    flex-direction: column;
    gap: 3px;
    display: flex;

    .stick {
      position: relative;
      height: 100px;
      width: 10px;
      border-radius: 16px;
      background: #f4f4f4;

      &>.bar {
        position: absolute;
        width: 10px;
        bottom: 0;
        left: 0;
        background: #b3c0ce;
        border-radius: 16px;
      }
    }
  }

  .bar-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;

    &.today-meet {
      &>.one-day {
        .stick {
          .bar.active {
            background-color: #28ace1;
          }
        }
      }
    }

    &.all-meet {
      &>.one-day {
        .stick {
          .bar.active {
            background-color: #09b66d;
          }
        }
      }
    }

    &.today-time {
      &>.one-day {
        .stick {
          .bar.active {
            background-color: #ff8a48;
          }
        }
      }
    }
  }

  .table-general {
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    .el-table {
      box-shadow: none !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;

      .el-table__header-wrapper {
        .el-table__header {
          padding-left: 24px;
        }
      }

      .el-table__body-wrapper {
        .el-table__body {
          .el-table__row {
            .el-table__cell {
              padding-left: 24px;
            }
          }
        }
      }
    }

    .search-status {
      display: flex;
      justify-content: space-between;
      margin-bottom: 42px;

      .search {
        .el-input {
          width: 290px;
          border-radius: 5px;

          ::placeholder {
            color: #8697a8 !important;
          }

          input {
            border: none;
            border-radius: 5px;
            background-color: white;
            color: #8697a8;
            font-size: 15px;
            font-weight: 400;
            line-height: 17px;
            box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
          }
        }

        .cell {
          word-break: break-word;
        }
      }

      .status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;

        .el-select {
          border-radius: 5px;
          max-width: 200px;

          &:first-child {
            width: 75px;
          }

          &:last-child {
            width: 200px;
          }

          border: none;

          .el-input {
            i {
              background: unset;
            }

            input {
              border-radius: 5px;
              border: none;
              color: #44566c;
              background-color: white;
              font-size: 15px;
              font-weight: 400;
              line-height: 17px;
              box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
            }
          }

          .el-input__inner::placeholder {
            color: #44566c;
          }
        }
      }
    }

    .pagination {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .info {
        font-size: 15px;
        font-weight: 400;
        color: #8697a8;
      }

      .el-pagination {
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
        background-color: white;
        border-radius: 8px;

        button {
          background-color: white;
          padding: 0;
          margin: 0;
          height: 100% !important;

          &.btn-prev {
            border-radius: 8px 0 0 8px;
            border-right: 1px solid #eaedf0;
          }

          &.btn-next {
            border-radius: 0 8px 8px 0;

            border-left: 1px solid #eaedf0;
          }
        }

        .el-pager {
          margin: 0 10px;
          padding: 5px;
          display: flex;
          flex-direction: row;
          gap: 5px;
          justify-content: space-between;

          li {
            padding: 0;
            margin: 0;
            background-color: white;

            &.active {
              background-color: #54d0c8;
              border-radius: 8px;
              box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.05);
            }
          }
        }
      }
    }

    .buttons {
      background-color: #ffffff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding-top: 20px;
      padding-bottom: 1rem;

      &> :first-child {
        margin-left: 48px !important;
      }

      .meetButton {
        width: 265px;
        height: 34px;
        align-items: center;
        padding: 0 !important;
        font-size: 15px;
        font-weight: 400;
        line-height: 17px;
        margin: 0;
        border: none;
        background-color: #f8fafb;

        &.active {
          background-color: #54d0c8 !important;
          color: #ffffff !important;

          :hover {
            background-color: #54d0c8 !important;
            color: #ffffff !important;
          }
        }
      }

      :hover {
        color: #54d0c8;
      }
    }
  }

  .color-dot {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .dot {
      height: 5px;
      width: 5px;
      background-color: black; // default #ff3d57 danger #FDBF5E kritik #09B66D neew
      border-radius: 50%;
      display: inline-block;

      &.red {
        background-color: #ff3d57;
      }

      &.green {
        background-color: #09b66d;
      }

      &.yellow {
        background-color: #fdbf5e;
      }
    }
  }

  .meeting-button {
    &.emergency {
      height: 100% !important;
      width: 100% !important;
      background-color: #0081ff !important;
      color: white !important;
      font-size: 15px !important;
      line-height: 17px !important;
      font-weight: 500 !important;
    }

    height: 100% !important;
    width: 100% !important;
    background-color: #a08bdb44 !important;
    color: #54d0c8 !important;
    font-size: 15px !important;
    line-height: 17px !important;
    font-weight: 500 !important;
  }
}
</style>
