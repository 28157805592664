import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
    filter: {
        PageSize: 10,
        SearchTerm: null,
        Status: null,
        Page: 1,
    },
    appointments: [],
    pendingAppointments: [],
    currentAppointment: {},
    currentMessage: [],
    currentDoctor: null,
}
export default {
    state,
    getters,
    actions,
    mutations
}