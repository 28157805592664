import { client } from "@/helpers/axiosHelpers"
export default {
    setLocalizationKeys(state, data){
        state.localisation = data;
    },
    setSocket(state,data){
        state.socket = data;
    },
    setEnums(state, data) {
        state.enums = data
    },
    setUser(state, data) {
        state.user = data
    },
    setToken(state, payload) {
        state.token = payload;
        client.defaults.headers["Authorization"] = "Bearer " + payload;
        localStorage.setItem("token", payload);
    },
    setNotification(state, data) {
        state.notification = data.Notification;
        state.notifyCount = data.TotalCount;
    },
    addNotification(state, data) {
        state.notification = state.notification.concat(data)
    },
    setStatistics(state, data) {
        state.statistics = data
    },
    setLanguage(state, data) {
        state.language = data
    },
}