export default {
  setInterviewList(state, data) {
    state.list = data;
  },
  setInterviewTotalCount(state, data) {
    state.result.TotalCount = data;
  },
  setInterviewPageCount(state, data) {
    state.result.PageCount = data;
  },
};
