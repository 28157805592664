import { client } from "@/helpers/axiosHelpers"
import { Message } from 'element-ui';

export default {
    async getMessagesMB(context, payload) {
        var res = await client.post("/MessageBox/List", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            context.commit("setMessagesMB", res.data.Data);
        }
        return res.data;
    },
    async sendMessageMB(context, payload) {

        var res = await client.post("/MessageBox/Send", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            //context.commit("setMessages", res.data.Data);
        }
        return res.data;
    },
    async toggleIsReadMB(context, payload) {
        var res = await client.post("/MessageBox/ToggleIsRead", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            //context.commit("setMessages", res.data.Data);
        }
        return res.data;
    },
    async toggleHasFlagMB(context, payload) {
        var res = await client.post("/MessageBox/ToggleHasFlag", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            //context.commit("setMessages", res.data.Data);
        }
        return res.data;
    },
    async deleteMessageMB(context, payload) {
        var res = await client.post("/MessageBox/Delete", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            //context.commit("setMessages", res.data.Data);
        }
        return res.data;
    },
    async getDoctorsMB(context, payload) {
        var res = await client.post("/User/List", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            context.commit("setDoctorsMB", res.data.Data);
        }
        return res.data;
    },
    async getNursesMB(context, payload) {
        var res = await client.post("/User/List", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            context.commit("setNursesMB", res.data.Data);
        }
        return res.data;
    },
}