import { client } from "@/helpers/axiosHelpers";
import { Message, MessageBox } from "element-ui";

export default {
    async getFaqList(context, payload) {
        var res = await client.post("/Faq/List", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            context.commit("setFaqList", res.data.Data.Faqs);
        }
    },
    async addOrUpdateFaq(context, payload) {
        context.commit('setFaqValidation', []);
        var res = await client.post("/Faq/AddOrUpdate", payload);
        context.commit('setFaqValidation', res.data.ValidationErrors);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            context.dispatch("getFaqList", context.state.filter);
            if (payload.ID == null) {
                Message.success("Soru başarıyla eklendi.");
            } else {
                Message.success("Soru başarıyla güncellendi.");
            }
        }
    },
    async deleteOneFaq(context, payload) {
        MessageBox.confirm("Bu soruyu tamamen silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.", "Uyarı", {
                confirmButtonText: "Evet, Sil",
                confirmButtonClass: "pop-confirm-class",
                cancelButtonText: "Vazgeç",
                cancelButtonClass: "pop-cancel-class",
                type: "warning",
            })
            .then(async() => {
                var res = await client.post("/Faq/Delete", {
                    Id: payload,
                });
                if (res.data.HasError) {
                    Message.error(res.data.Message);
                } else {
                    Message.success("Soru başarıyla silindi.");
                    await context.dispatch("getFaqList", context.state.filter);
                }
            })
            .catch(() => {});
    },
    async deleteAllFaq(context, payload) {
        MessageBox.confirm("Seçilenleri tamamen silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.", "Uyarı", {
                confirmButtonText: "Evet, Sil",
                confirmButtonClass: "pop-confirm-class",
                cancelButtonText: "Vazgeç",
                cancelButtonClass: "pop-cancel-class",
                type: "warning",
            })
            .then(async() => {
                await Promise.all(
                    payload.map(async(item) => {
                        var res = await client.post("/Faq/Delete", {
                            Id: item.ID,
                        });
                        if (res.data.HasError) {
                            Message.error(res.data.Message);
                        }
                    }),
                );
                await context.dispatch("getFaqList", context.state.filter);
            })
            .catch(() => {});
    },

};