import { client } from "@/helpers/axiosHelpers"
import { Message } from 'element-ui';

export default {
    async setPermissionCameraAndMicrophone(context,payload){
        var res = await client.post('/Appointment/CameraAndMicPermission', payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        }
    },
    async getListUserAppointments(context, payload) {
        var res = await client.post("/Appointment/List", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            context.commit("setUserAppointments", res.data.Data);
            return res.data.Data.Appointments;
        }
    },
    async getCurrentAppointmentMessages(context, payload) {
        var res = await client.post("/Appointment/ListMessages", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            context.commit("setCurrentAppoinmentMessage", res.data.Data.Items);
        }
    },
    async setDoctorNotes(context, payload) {
        var res = await client.post("/Appointment/UpdateDoctorDiagnosis", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        }
    },
}