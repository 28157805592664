import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
    filter: {
        PageSize:100,
        Page:1,
        SearchTerm:null,
        MessageBoxType:1,
        Tag:null, 
        SortingType:1
    },
    sendMessage: {
        ReceiverId: null,
        RepliedMessageId: null,
        Title: "",
        MessageText: "",
        MessageText2: "",
        Attachments: []
    },
    doctors: {
        Items: [],
        TotalCount:0,
        PageCount:0
    },
    nurses: {
        Items: [],
        TotalCount:0,
        PageCount:0
    },
    activeMessage: null,
    messages: {
        Items:[],
        TotalCount:0,
        PageCount:0
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}