import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  filter: {
    PageSize: 10,
    Page: 1,
    SearchTerm: null,
    Role: 1,
    CreatedDateMin: null,
    CreatedDateMax: null,
    DateRange: null,
    ActiveStatus:null
  },
  multiSelect: [],
  result: {
    Items: [],
    TotalCount: 0,
    PageCount: 0,
  },
  list: [],
};
export default {
  getters,
  actions,
  mutations,
  state,
};
