import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
    validationErrors: [],
    filter: {
        CreatedAtMin:null,
        CreatedAtMax:null,
        Extension:null,
        ParentItemId:null,
        DocumentCategoryId:null,
        DateRange:null,
        PageSize:100,
        SearchTerm:null,
        Page:1,
        ListType:0 // arayüz görünümü
    },
    documentCategories: {
        Items:[]
    },
    documents: {
        Items:[],
        TotalCount:0,
        PageCount:0
    },
    addUpdateDocumentCategory: {
        Id:null,
        OrderNo:0,
        Title:null,
        Color:null
    },
    uploadDocuments: {
        DocumentCategoryId: null,
        ParentDocumentId: null,
        Attachments: []
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}