export default {
    setPostList(state, data) {
        state.list = data;
    },
    setPostValidation(state, data) {
        state.validation = data;
    },
    setPostTotalCount(state, data) {
        state.result.TotalCount = data;
    },
    setPostPageCount(state, data) {
        state.result.PageCount = data;
    },
 

}