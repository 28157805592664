import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  filter: {
    Page: 1,
    PageSize: 10,
    SearchTerm: null,
    UserActions:null,
    ObjectType:null,
    HistoryStatus:null
  },
  result: {
    Items: [],
    TotalCount: 0,
    PageCount: 0,
  },
  list: [],
  validation: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};