<template>
  <div class="control-container">
    <div class="all-items" v-if="currentAppointment.Status != 3">
      <div class="item">
        <div class="icon-container timer" :class="{ danger: this.AppointmentDateTime < 180000 }" v-if="AppointmentDate != null" style="text-align: center">
          <vue-countdown-timer @end_callback="endCallBack('Bitti')" :end-txt="$localization('web-control-finish')" :start-time="new Date()" :end-time="new Date(AppointmentDate)" :interval="1000">
            <template slot="countdown" slot-scope="scope">
              <div>
                <span>{{ scope.props.minutes }}</span>:
                <span>{{ scope.props.seconds }}</span>
              </div>
            </template>
          </vue-countdown-timer>
        </div>
        <div class="title">{{ $localization('web-control-time-left') }}</div>
      </div>
      <div class="item">
        <div class="icon-container camera" @click="toggleWebcam()">
          <div :class="webcamState == 'on' ? 'icon-video' : 'icon-video-off'"></div>
        </div>
        <div class="title">{{ $localization('web-control-camera') }}</div>
      </div>
      <div class="item">
        <div class="icon-container microphone" @click="toggleMicState()">
          <div :class="micState == 'on' ? 'icon-microphone' : 'icon-microphone-off'"></div>
        </div>
        <div class="title">{{ $localization('web-control-mic') }}</div>
      </div>
      <div class="item">
        <div class="icon-container audio" @click="toggleMute()">
          <div :class="!muted ? 'icon-audio' : 'icon-audio-off'"></div>
        </div>
        <div class="title">{{ $localization('web-control-audio') }}</div>
      </div>
      <div class="item">
        <el-popconfirm :title="$localization('web-control-are-you-sure-to-end-meet')" confirm-button-type="success" cancel-button-type="text" :confirm-button-text="$localization('web-control-yes')"
          :cancel-button-text="$localization('web-control-no')" @confirm="endAppointment">
          <div class="icon-container end" slot="reference">
            <div class="icon-room-end"></div>
          </div>
        </el-popconfirm>
        <div class="title">{{ $localization('web-control-end-meet') }}</div>
      </div>
    </div>

    <el-dialog custom-class="rate-dialog" :visible.sync="rateDialog" :close-on-click-modal="false" width="90%" :before-close="handleClose">
      <div class="container">
        <div class="title">{{ $localization('web-control-rate-meet') }}</div>
        <div class="input-container">
          <div class="title">{{ $localization('web-control-consultant') }}</div>
          <div class="content" v-if="currentAppointment.DoctorUser != null">{{ currentAppointment.DoctorUser.Title }}</div>
          <span class="error"> </span>
        </div>
        <div class="input-container">
          <div class="title">{{ $localization('web-control-rate') }}</div>
          <el-input type="textarea" maxlength="450" :rows="2" :placeholder="$localization('web-control-enter-your-rate')" v-model="rateObj.Notes">
          </el-input>
          <span class="error"> {{ $errorMessage("Notes", ValidationErrors) }}</span>
        </div>
        <div class="input-container">
          <div class="title">{{ $localization('web-control-rate-point') }}</div>
          <el-rate v-model="rateObj.Rate"></el-rate>

          <span class="error" v-if="ValidationErrors.length > 0"> {{ $errorMessage("Rate", ValidationErrors) }} </span>
        </div>

        <div class="input-container">
          <div class="title">{{ $localization('web-control-recommend-elra') }}</div>
          <div class="radio-group">
            <el-radio v-model="rateObj.Recommendation" :label="true">{{ $localization('web-control-yes') }}</el-radio>
            <el-radio v-model="rateObj.Recommendation" :label="false">{{ $localization('web-control-no') }}</el-radio>
          </div>
          {{ $errorMessage("Recommendation", ValidationErrors) }}
        </div>
        <div class="input-container">
          <div class="title">{{ $localization('web-control-where-did-you-find-us') }}</div>
          <el-input type="textarea" maxlength="450" :rows="2" placeholder="..." v-model="rateObj.HowYouReach"> </el-input>
          <!-- {{ $errorMessage("Recommendation", ValidationErrors) }} -->
        </div>

        <div class="button-container">
          <el-button class="danger" @click="handleClose()"> {{ $localization('web-control-cancel') }} </el-button>
          <el-button @click="rateSend()">
            <i class="icon-send"></i>
            {{ $localization('web-control-send') }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as cookiesManager from "../../../utils/cookiesManager";

export default {
  props: {
    roomClient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rateObj: {
        AppointmentId: this.$route.params.id,
        Notes: null,
        Rate: 0,
        Recommendation: true,
        HowYouReach: null,
      },
      rateDialog: false,
      camera: false,
      microphone: false,
      muted: false,
      Appointment: null,
      AppointmentDate: null,
      ValidationErrors: [],
      AppointmentDateTime: null,
    };
  },

  async mounted() {
    var res = await this.$client.post("/Appointment/Get", { AppointmentId: this.$route.params.id });
    if (res.data.HasError) {
      this.$message.error(res.data.ErrorMessage);
    } else {
      this.Appointment = res.data.Data.Appointment;
      this.AppointmentDate = this.$moment(res.data.Data.Appointment.EndDate).toISOString();
    }
  },

  methods: {
    async rateSend() {
      var res = await this.$client.post("/Appointment/Rate", this.rateObj);
      this.ValidationErrors = res.data.ValidationErrors;
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(this.$localization('web-control-rate-sent-successful'));
        setTimeout(() => {
          this.$router.push("/kullanici");
        }, 2000);
      }
    },
    handleClose() {
      this.rateDialog = false;
      this.$router.push({ path: "/kullanici" });
    },
    endCallBack: function () {
      if (this.user.Role == 1) {
        if (this.rateDialog != true) {
          this.rateDialog = true;
        }
      }
    },
    async endAppointment() {
      var res = await this.$client.post("/Appointment/FastComplete", { AppointmentId: this.$route.params.id });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        if (this.user.Role == 1) {
          if (this.rateDialog != true) {
            this.rateDialog = true;
          }
        }
      }
      this.roomClient.muteMic();
      this.roomClient.disableWebcam();

      //TODO: Özkan - Muhammet, routerdan anasayfaya göndermen yeterli, hatta değerlendirme popup ı açıp değerlendirdikten sonra yapabilirsin.
      //MeetingRoom beforedestroyda her şeyi kapatıyor çünkü
    },
    toggleShare() {
      if (this.shareState === "on") {
        this.roomClient.disableShare();
      } else {
        this.roomClient.enableShare();
      }
    },
    changeWebcam() {
      this.roomClient.changeWebcam();
    },
    toggleWebcam() {
      if (this.webcamState == "on") {
        cookiesManager.setDevices({ webcamEnabled: false });
        this.roomClient.disableWebcam();
      } else {
        cookiesManager.setDevices({ webcamEnabled: true });
        this.roomClient.enableWebcam();
      }
    },
    toggleMicState() {
      this.micState === "on" ? this.roomClient.muteMic() : this.roomClient.unmuteMic();
    },
    toggleMute() {
      try {
        for (var i = 0; i < this.$store.state.room.peers.length; i++) {
          var elem = document.getElementById("audio-" + this.$store.state.room.peers[i].id);
          if (elem != null) {
            if (elem.paused) {
              this.muted = false;
              elem.play();
            } else {
              this.muted = true;
              elem.pause();
            }
          }
        }
      } catch (e) {
        console.warn(e);
      }
    },
  },
  computed: {
    currentAppointment() {
      return this.$store.getters.getCurrentAppointment;
    },
    user() {
      return this.$store.getters.getUser;
    },
    producers() {
      return this.$store.state.room.producers;
    },
    audioProducer() {
      return this.producers.find((producer) => producer.track.kind === "audio");
    },
    videoProducer() {
      return this.producers.find((producer) => producer.track.kind === "video" && producer.type !== "share");
    },
    shareProducer() {
      return this.producers.find((producer) => producer.type === "share");
    },
    me() {
      return this.$store.state.room.me;
    },
    webcamState() {
      let webcamState;

      if (!this.me.canSendWebcam) {
        webcamState = "unsupported";
      } else if (this.videoProducer && this.videoProducer.type !== "share") {
        webcamState = "on";
      } else {
        webcamState = "off";
      }

      return webcamState;
    },
    micState() {
      let micState;

      if (!this.me.canSendMic) {
        micState = "unsupported";
      } else if (!this.audioProducer) {
        micState = "unsupported";
      } else if (!this.audioProducer.paused) {
        micState = "on";
      } else {
        micState = "off";
      }

      return micState;
    },
  },
  watch: {
    currentAppointment() {
      var ex = new Date(new Date(this.currentAppointment.EndDate) - new Date()).getTime();
      this.AppointmentDateTime = ex;
    },
  },
};
</script>

<style lang="less">
.el-popconfirm {
  .el-button--text {
    color: #54d0c8 !important;
  }

  .el-button--success {
    background-color: #54d0c8 !important;
    color: white !important;
    border: none !important;
  }
}
</style>
