<template>
  <div class="credit-card-components">
    <div class="top">
      <img src="../assets/img/icons/ccc.png" alt="Credit Card" />
      <img src="../assets/img/icons/visa.png" class="visa" alt="Visa Card" />
    </div>
    <div class="center">
      {{ data.CardNO | VMask('#### #### #### ####') }}
      <div class="period" v-if="data.CardNO.length <= 7">
        <div class="icon-period" v-for="i in (16 - data.CardNO.length)" :key="i"></div>
      </div>
    </div>
    <div class="bottom">
      <div class="item">
        <div class="title">{{ $localization('web-creditcard-cardholder') }}</div>
        <div class="desc">{{ data.CardName }}</div>
      </div>
      <div class="item" v-if="data.ExpireDate != null">
        <div class="title">{{ $localization('web-creditcard-expire-date') }}</div>
        <div class="desc">{{ data.ExpireDate }}</div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="divider two"></div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  name: "CreditCardComponent",
};
</script>

<style></style>
