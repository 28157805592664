import { client } from "@/helpers/axiosHelpers";
import { Message,MessageBox} from "element-ui";

export default {
    async addUpdateChild(context, payload) {
        context.commit('setChildrenValidation', []);
        var res = await client.post("/Account/AddUpdateKid", payload);
        context.commit('setChildrenValidation', res.data.ValidationErrors);
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
            context.dispatch("getChildrenList", context.state.filter);
          if (payload.ID != null) {
            Message.success("Çocuk başarıyla güncellendi.");
          } else {
            Message.success("Çocuk başarıyla eklendi.");
          }
        }
      },   
      async getChildrenList(context) {
        var res = await client.post("/Account/ListKids", context.state.filter);
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
          context.commit("setChildrenList",res.data.Data.Items) ;
        }
      }, 
      async deleteChild(context,payload){
        MessageBox.confirm(
            "Seçtiğiniz çocuğu tamamen silmek istediğiniz emin misiniz? Bu işlem geri alınamaz.",
            "Uyarı",
            {
              confirmButtonText: "Evet, Sil",
              confirmButtonClass: "pop-confirm-class",
              cancelButtonText: "Vazgeç",
              cancelButtonClass: "pop-cancel-class",
              type: "warning",
            }
          )
            .then(async () => {
              var res = await client.post("/Account/DeleteChild",payload);
              if (res.data.HasError) {
                Message.error(res.data.Message);
              } else {
                Message.success("Çocuk başarıyla silindi.");
                await context.dispatch("getChildrenList", context.state.filter);
              }
              })
            .catch(() => {});
      },
};
