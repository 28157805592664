import { client } from "@/helpers/axiosHelpers";
import { Message,MessageBox} from "element-ui";

export default {
    async getCategoryList(context) {
        var response = await client.post("/Blog/ListBlogCategories", context.state.filter);
        if (response.data.HasError) {
          Message.error(response.data.Message);
        } else {
          context.commit("setCategoryList",response.data.Data.Items) ;
        }
      },
      async addOrUpdateCategory(context, payload) {
        context.commit('setCategoryValidation', []);
        var res = await client.post("/Blog/AddUpdateBlogCategory", payload);
        context.commit('setCategoryValidation', res.data.ValidationErrors);
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
            context.dispatch("getCategoryList", context.state.filter);
          if (payload.ID != null) {
            Message.success("Kategori başarıyla güncellendi.");
          } else {
            Message.success("Kategori başarıyla eklendi.");
          }
        }
      }, 
      async deleteAllCategory(context, payload) {
        MessageBox.confirm(
          "Seçili kategorileri tamamen silmek istediğiniz emin misiniz? Bu işlem geri alınamaz.",
          "Uyarı",
          {
            confirmButtonText: "Evet, Sil",
            confirmButtonClass: "pop-confirm-class",
            cancelButtonText: "Vazgeç",
            cancelButtonClass: "pop-cancel-class",
            type: "warning",
          }
        )
          .then(async () => {
            await Promise.all(
              payload.map(async (element) => {
                var res = await client.post("/Blog/DeleteBlogCategory", {
                  Id: element.ID,
                });
                if (res.data.HasError) {
                  Message.error(res.data.Message);
                }
              })
            );
            await context.dispatch("getCategoryList", context.state.filter);
          })
          .catch(() => {});
      },
      async deleteOneCategory(context, payload) {
        MessageBox.confirm(
          "Seçtiğiniz kategoriyi tamamen silmek istediğiniz emin misiniz? Bu işlem geri alınamaz.",
          "Uyarı",
          {
            confirmButtonText: "Evet, Sil",
            confirmButtonClass: "pop-confirm-class",
            cancelButtonText: "Vazgeç",
            cancelButtonClass: "pop-cancel-class",
            type: "warning",
          }
        )
          .then(async () => {
            var res = await client.post("/Blog/DeleteBlogCategory", {
              Id: payload,
            });
            if (res.data.HasError) {
              Message.error(res.data.Message);
            } else {
              Message.success("Kategori başarıyla silindi.");
              await context.dispatch("getCategoryList", context.state.filter);
            }
            })
          .catch(() => {});
      },
      async changeStatusCategory(context, payload) {
        var res = await client.post("/Blog/ToggleIsActiveBlogCategory", {
          Id: payload,
        });
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
          Message.success("Başarıyla Güncellendi");
          await context.dispatch("getCategoryList", context.state.filter);
        }
      },

};