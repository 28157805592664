export default {
  setUsersList(state, data) {
    state.list = data;
  },
  setUsersTotalCount(state, data) {
    state.result.TotalCount = data;
  },
  setUsersPageCount(state, data) {
    state.result.PageCount = data;
  },
  setMultipleSelect(state, data) {
    state.multiSelect = data;
  },
};
