import Vue from 'vue'

export default {

    //me
    setRoomState(state, payload) {
        const roomState = payload.state

        if (roomState !== 'connected') {
            state.state = roomState
            state.activeSpeakerId = null
            state.statsPeerId = null
        } else {
            state.state = roomState
        }

        if (payload.state === 'closed') {
            state.me.webcamInProgress = false
            state.me.shareInProgress = false
            state.me.audioOnly = false
            state.me.audioOnlyInProgress = false
            state.me.audioMuted = false
            state.me.restartIceInProgress = false
            state.peers = []
            state.producers = []
            state.dataProducers = []
            state.dataConsumers = []
            state.consumers = []
            state.dataConsumers = []
        }
    },

    setMe(state, payload) {
        const { peerId, displayName, displayNameSet, device, title,isChild,jwtToken } = payload
        state.me.id = peerId
        state.me.isChild = isChild
        state.me.jwtToken = jwtToken
        state.me.displayName = displayName
        state.me.displayNameSet = displayNameSet
        state.me.device = device
        if (title != null) {
            state.me.title = title
        }
    },
    setRoomChildren(state,payload){
        state.children = payload;
    },
    setMediaCapabilities(state, payload) {
        const { canSendMic, canSendWebcam } = payload

        state.me.canSendMic = canSendMic
        state.me.canSendWebcam = canSendWebcam
    },

    setCanChangeWebcam(state, payload) {
        const { canChangeWebcam } = payload

        state.me.canChangeWebcam = canChangeWebcam
    },

    setWebcamInProgress(state, payload) {
        const { flag } = payload

        state.me.webcamInProgress = flag
    },

    setShareInProgress(state, payload) {
        const { flag } = payload

        state.me.shareInProgress = flag
    },

    setDisplayName(state, payload) {
        const { displayName } = payload

        if (displayName) {
            state.me.displayName = displayName
        }
    },

    setAudioOnlyState(state, payload) {
        const { enabled } = payload

        state.me.audioOnly = enabled
    },

    setAudioOnlyInProgress(state, payload) {
        const { flag } = payload

        state.me.audioOnlyInProgress = flag
    },

    setAudioMutedState(state, payload) {
        const { enabled } = payload

        state.me.audioMuted = enabled
    },

    setRestartIceInProgress(state, payload) {
        const { flag } = payload

        state.me.restartIceInProgress = flag
    },

    //peers
    addPeer(state, payload) {
        const { peer } = payload
        state.peers.push(peer)
    },

    removePeer(state, payload) {
        const { peerId } = payload

        if (peerId && peerId === state.activeSpeakerId) {
            state.activeSpeakerId = null
        }

        if (peerId && peerId === state.statsPeerId) {
            state.statsPeerId = null
        }


        state.peers = state.peers.filter((peer) => peer.id !== peerId)
    },

    setPeerDisplayName(state, payload) {
        const { displayName, peerId } = payload
        const peer = state.peers.find((peer) => peer.id === peerId)

        if (!peer) {
            throw new Error('no Peer found')
        }

        state.peer.displayName = displayName
    },

    addConsumerToPeer(state, payload) {
        const { consumer, peerId } = payload
        const peer = state.peers.find((peer) => peer.id === peerId)

        if (!peer) {
            throw new Error('no Peer found for new Consumer')
        }

        peer.consumers.push(consumer.id)
    },

    removeConsumerFromPeer(state, payload) {
        const { consumerId, peerId } = payload
        const peer = state.peers.find((peer) => peer.id === peerId)

        // NOTE: This means that the Peer was closed before, so it's ok.
        if (!peer) {
            return
        }

        peer.consumers = peer.consumers.filter(
            (consumer) => consumer === consumerId
        )
    },

    addDataConsumerToPeer(state, payload) {
        const { dataConsumer, peerId } = payload

        // special case for bot DataConsumer.
        if (!peerId) {
            return
        }

        const peer = state.peers.find((peer) => peer.id === peerId)

        if (!peer) {
            throw new Error('no Peer found for new DataConsumer')
        }

        peer.dataConsumers.push(dataConsumer.id)
    },

    removeDataConsumerFromPeer(state, payload) {
        const { dataConsumerId, peerId } = payload

        // special case for bot DataConsumer.
        if (!peerId) {
            return
        }

        const peer = state.peers.find((peer) => peer.id === peerId)

        // NOTE: This means that the Peer was closed before, so it's ok.
        if (!peer) {
            return
        }

        peer.dataConsumers = peer.dataConsumers.filter(
            (dataConsumer) => dataConsumer === dataConsumerId
        )
    },

    //producers
    addProducer(state, payload) {
        const { producer } = payload

        state.producers.push(producer)
    },

    removeProducer(state, payload) {
        const { producerId } = payload

        state.producers = state.producers.filter(
            (producer) => producer.id !== producerId
        )
    },

    setProducerPaused(state, payload) {
        const { producerId } = payload

        const producer = state.producers.find(
            (producer) => producer.id === producerId
        )

        producer.paused = true
    },

    setProducerResumed(state, payload) {
        const { producerId } = payload

        const producer = state.producers.find(
            (producer) => producer.id === producerId
        )

        producer.paused = false
    },

    setProducerTrack(state, payload) {
        const { producerId, track } = payload

        const producer = state.producers.find(
            (producer) => producer.id === producerId
        )

        producer.track = track
    },

    setProducerScore(state, payload) {
        const { producerId, score } = payload

        const producer = state.producers.find(
            (producer) => producer.id === producerId
        )

        producer.score = score
    },

    //data producers
    addDataProducer(state, payload) {
        const { dataProducer } = payload
        state.dataProducers.push(dataProducer)
    },

    removeDataProducer(state, payload) {
        const { dataProducerId } = payload
        state.dataProducers = state.dataProducers.filter(
            (consumer) => consumer.id !== dataProducerId
        )
    },

    //data consumers
    addDataConsumer(state, payload) {
        const { dataConsumer } = payload
        state.dataConsumers.push(dataConsumer)
    },

    removeDataConsumer(state, payload) {
        const { dataConsumerId } = payload
        state.dataConsumers = state.dataConsumers.filter(
            (consumer) => consumer.id !== dataConsumerId
        )
    },

    //consumers
    addConsumer(state, payload) {
        state.consumers.push(payload.consumer)
    },

    removeConsumer(state, payload) {
        const consumerId = payload.consumerId

        state.consumers = state.consumers.filter(
            (consumer) => consumer.id !== consumerId
        )
    },

    setConsumerPaused(state, payload) {
        const { consumerId, originator } = payload
        const consumer = state.consumers.find(
            (consumer) => consumer.id === consumerId
        )

        if (originator === 'local') {
            Vue.set(consumer, 'locallyPaused', true)
        } else {
            Vue.set(consumer, 'remotelyPaused', true)
        }
    },

    setConsumerResumed(state, payload) {
        const { consumerId, originator } = payload
        const consumer = state.consumers.find(
            (consumer) => consumer.id === consumerId
        )

        if (originator === 'local') {
            Vue.set(consumer, 'locallyPaused', false)
        } else {
            Vue.set(consumer, 'remotelyPaused', false)
        }
    },

    setConsumerCurrentLayers(state, payload) {
        const { consumerId, spatialLayer, temporalLayer } = payload
        const consumer = state.consumers.find(
            (consumer) => consumer.id === consumerId
        )

        Vue.set(consumer, 'currentSpatialLayer', spatialLayer)
        Vue.set(consumer, 'currentTemporalLayer', temporalLayer)
    },

    setConsumerPreferredLayers(state, payload) {
        const { consumerId, spatialLayer, temporalLayer } = payload
        const consumer = state.consumers.find(
            (consumer) => consumer.id === consumerId
        )

        Vue.set(consumer, 'preferredSpatialLayer', spatialLayer)
        Vue.set(consumer, 'preferredTemporalLayer', temporalLayer)
    },

    setConsumerPriority(state, payload) {
        const { consumerId, priority } = payload
        const consumer = state.consumers.find(
            (consumer) => consumer.id === consumerId
        )

        Vue.set(consumer, 'priority', priority)
    },

    setConsumerTrack(state, payload) {
        const { consumerId, track } = payload
        const consumer = state.consumers.find(
            (consumer) => consumer.id === consumerId
        )

        Vue.set(consumer, 'track', track)
    },

    setConsumerScore(state, payload) {
        const { consumerId, score } = payload
        const consumer = state.consumers.find(
            (consumer) => consumer.id === consumerId
        )

        Vue.set(consumer, 'score', score)
    },

    //room
    setRoomUrl(state, payload) {
        state.room.url = payload.url
    },
    setRoomActiveSpeaker(state, payload) {
        const { peerId } = payload

        state.room.activeSpeakerId = peerId
    },

    notify(state, payload) {
        console.log(payload);
    },

    setRoomStatsPeerId(state, payload) {
        const { peerId } = payload

        if (state.room.statsPeerId === peerId) {
            state.room.statsPeerId = null
        }

        state.room.statsPeerId = peerId
    },

    setFaceDetection(state, payload) {
        const { flag } = payload

        state.room.faceDetection = flag
    },
}