import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
    filter: {
        Page: 1,
        PageSize: 100,
        SearchTerm: null,
    },
    list: [],
    validation: [],
}

export default {
    state,
    getters,
    actions,
    mutations
}