<template>
  <!-- {{ $localization('mahmut').replace("{0}", mahmut) }} -->
  <!-- {{ $localization('mahmut') }} -->
  <!--  $localization('mahmut')  -->
  <!-- :placeholder="$localization('mahmut')" -->
  <!-- this.$localization('mahmut') -->
  <div class="elramed-login-screen">
    <div class="background"></div>
    <div class="login-container">
      <div class="login-top">
        <div class="left">
          <img src="../assets/img/login.png" alt="" />
        </div>
        <div class="right">
          <img src="../assets/img/logo.png" alt="" />
          <div class="login" @keyup.enter="otpScreen()">
            <div class="title">{{ $localization("web-login-sign-in") }}</div>
            <div class="description" v-if="otp == false">{{ $localization("web-login-you-can-enter-you-phone") }}</div>
            <div class="description" v-else>{{ $localization("web-login-enter-otp-code") }}</div>
            <div class="input-container" v-if="otp == false">
              <div class="phone">
                <vue-phone-number-input v-model="input" @update="
                  phoneFormatter = $event;
                changeNumber();
                " :default-phone-number="initPhoneNumberExample" :no-example="true" ref="phoneInput2" :translations="{
  countrySelectorLabel: $localization('web-login-country-code'),
  countrySelectorError: $localization('web-login-select-country-code'),
  phoneNumberLabel: $localization('web-login-phone-number'),
  example: $localization('web-login-example'),
}" size="lg" default-country-code="TR" />
              </div>
              <span class="error">{{ $errorMessage("PhoneNumber", ValidationErrors) }}</span>
            </div>
            <div class="otp-wrapper" v-else>
              <div class="otps" @keydown="handleKeydown">
                <el-input ref="otp1" placeholder="" v-mask="'#'" class="otp" v-model="otp1" maxlength="1"></el-input>
                <el-input ref="otp2" placeholder="" v-mask="'#'" class="otp" v-model="otp2" maxlength="1"></el-input>
                <el-input ref="otp3" placeholder="" v-mask="'#'" class="otp" v-model="otp3" maxlength="1"></el-input>
                <el-input ref="otp4" placeholder="" v-mask="'#'" class="otp" v-model="otp4" maxlength="1"></el-input>
                <el-input ref="otp5" placeholder="" v-mask="'#'" class="otp" v-model="otp5" maxlength="1"></el-input>
                <el-input ref="otp6" placeholder="" v-mask="'#'" class="otp" v-model="otp6" maxlength="1"></el-input>
              </div>
              <div class="timer">
                <vue-countdown-timer @end_callback="endCallback" :end-text="'Süre Doldu!'" :start-time="new Date().getTime()" :end-time="new Date().getTime() + 60000" :interval="1000">
                  <template slot="countdown" slot-scope="scope">
                    <div class="otp-timer">
                      <span>{{ scope.props.seconds }}</span>
                    </div>
                  </template>
                </vue-countdown-timer>
              </div>
            </div>
            <!-- env'den gelmesi isteniyorsa =>  :sitekey="this.recaptchaKey" elle girilecekse => sitekey="6Le43WYkAAAAAAD1SG1x1Tt_GYJEfVzFk554jd6g" -->
            <vue-recaptcha ref="recaptcha" @verify="verifyMethod" size="invisible" loadRecaptchaScript :sitekey="this.recaptchaKey"></vue-recaptcha>
            <title>
              <%= process.env.VUE_APP_RECAPTCHA %>
            </title>
            <el-button style="width: 100%" class="login-btn" v-if="!otp" v-on:click="otpScreen()" :loading="loading" :disabled="loading">{{ $localization("web-login-sign-in") }}</el-button>

            <el-select v-model="language" style="width: 100%" class="tc" @change="setLanguage(language)" v-if="!otp">
              <el-option v-for="item in languageList" :key="item.ID" :label="item.Title" :value="item.LanguageCode" style="display: flex; align-items: center">
                <img :src="item.Image" style="height: 20px; width: 30px; margin-right: 10px" />
                {{ item.Title }}
              </el-option>
            </el-select>
          </div>
          <div class="register">
            {{ $localization("web-login-are-you-sign-up-yet") }} <router-link tag="a" to="/kayıt-ol">{{
              $localization("web-login-sign-up") }}</router-link>
          </div>
        </div>
      </div>
      <el-button class="back-btn" @click="goWebSite()">{{ $localization("web-login-return-to-website") }}</el-button>

      <!-- HS 27/05/2023 !!! versiyon kaldırıldı
      <div class="version">
        <p>© 2022 elra.health developed by <a target="_blank" rel="nofollow" href="https://internative.net/">Internative</a> and <a target="_blank" rel="nofollow" href="http://www.gbks.com.tr/">Global Bilgisayar</a> {{this.version}}</p>       
      </div>
    -->

    </div>
  </div>
</template>
<!-- <script src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit" async defer></script> -->
<script>
import { VueRecaptcha } from "vue-recaptcha";
export default {
  components: { VueRecaptcha },
  name: "Login",
  data() {
    return {
      languageList: [],
      language: null,
      phoneFormatter: null,
      loading: false,
      ValidationErrors: [],
      input: "",
      initPhoneNumberExample: "555 555 55 55",
      phone: null,
      otp: false,
      otp1: null,
      otp2: null,
      otp3: null,
      otp4: null,
      otp5: null,
      otp6: null,
      GRecaptchaResponse: null,
      /*recaptchayı enviormentten çekmek için eklendi*/
      recaptchaKey: process.env.VUE_APP_RECAPTCHA,
      version: process.env.VUE_APP_VERSION,
    };
  },
  async beforeMount() {
    await this.getLanguageList();
    await this.setLanguage(localStorage.getItem("language"));
    this.language = localStorage.getItem("language");
  },
  mounted() { },
  methods: {
    async getLanguageList() {
      var res = await this.$client.post("/Localization/SystemLanguages");
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.languageList = res.data.Data.Items;
      }
    },
    async setLanguage(langCode) {
      await this.$store.dispatch("setLanguageLocalisationKey", langCode);
    },
    verifyMethod(payload) {
      this.GRecaptchaResponse = payload;
      // this.otpScreen();
    },
    expiredToken() {
      this.$message.error(this.$localization("web-login-captcha-invalid"));
    },
    goWebSite() {
      // orjinal hali  https://elra.health/!!! HS 31/03/2023 -+
      //window.open("https://elra.health/", "_blank");
      window.open(process.env.VUE_APP_SITE_URL, "_blank");
    },
    endCallback: function () {
      this.otp = false;
      this.otp1 = null;
      this.otp2 = null;
      this.otp3 = null;
      this.otp4 = null;
      this.otp5 = null;
    },
    handleKeydown(e) {
      if (e.key === "Backspace" || e.key === "Delete") {
        if (this.otp6 == null || this.otp6 == "") {
          this.$refs.otp5.focus();
        }
        if (this.otp5 == null || this.otp5 == "") {
          this.$refs.otp4.focus();
        }

        if (this.otp4 == null || this.otp4 == "") {
          this.$refs.otp3.focus();
        }

        if (this.otp3 == null || this.otp3 == "") {
          this.$refs.otp2.focus();
        }

        if (this.otp2 == null || this.otp2 == "") {
          this.$refs.otp1.focus();
        }

        if (this.otp1 == null || this.otp1 == "") {
          this.$refs.otp1.focus();
        }
      }
    },
    async otpScreen() {
      this.ValidationErrors = [];
      this.loading = true;
      if (this.phoneFormatter != null) {
        if (this.phoneFormatter.isValid != false) {
          this.phone = this.phoneFormatter.formatInternational.replaceAll(" ", "");
        } else {
          this.loading = false;
        }
      } else {
        this.$message.warning(this.$localization("web-login-please-enter-valid-phone"));
        this.loading = false;
        return;
      }
      await this.$refs.recaptcha.execute();
      if (this.GRecaptchaResponse == null) {
        setTimeout(() => this.otpScreen(), 500);
        return;
      }
      var res = await this.$client.post("/Login/SendSignInOtpWeb", { PhoneNumber: this.phone, GRecaptchaResponse: this.GRecaptchaResponse });
      this.GRecaptchaResponse = null;
      await this.$refs.recaptcha.reset();
      this.ValidationErrors = res.data.ValidationErrors;

      if (res.data.HasError) {
        this.$notify({
          title: this.$localization("web-register-warning"),
          message: res.data.Message,
          type: "warning",
          duration: 15000,
        });
      } else {
        this.otp = true;
        this.$notify({
          title: "",
          message: res.data.Message,
          type: "success",
          duration: 15000,
        });
        this.$nextTick(() => {
          this.$refs.otp1.focus();
        });
      }
      this.loading = false;
    },
    changeNumber() {
      var div = document.getElementsByClassName("input-tel__label");
      div.innerText = div[0].innerText.replaceAll("0501", "501");
    },
    async verifyOtp() {
      this.ValidationErrors = [];
      if (this.phoneFormatter != null) {
        if (this.phoneFormatter.isValid != false) {
          this.input = this.phoneFormatter.formatInternational.replaceAll(" ", "");
        }
      } else {
        this.$message.warning(this.$localization("web-login-please-enter-valid-phone"));
        return;
      }
      //this.otp = false;
      var otpCode = `${this.otp1}${this.otp2}${this.otp3}${this.otp4}${this.otp5}${this.otp6}`;
      var res = await this.$client.post("/Login/ValidateSignInOtp", { PhoneNumber: this.phone, ConfirmationCode: otpCode });
      this.ValidationErrors = res.data.ValidationErrors;

      if (res.data.HasError) {
        this.$notify({
          title: "Hata",
          message: res.data.Message,
          type: "error",
        });
        await this.$nextTick(() => {
          this.otp1 = null;
          this.otp2 = null;
          this.otp3 = null;
          this.otp4 = null;
          this.otp5 = null;
          this.otp6 = null;
        });
        this.$nextTick(async () => {
          await this.$refs.otp1.focus();
        });
      } else {
        await this.$store.commit("setToken", res.data.Data.Token);
        this.$nextTick(() => {
          this.$router.push("/");
        });
      }
    },
  },
  watch: {
    otp1: function () {
      if (this.otp1 != null && this.otp1.length == 1) {
        this.$refs.otp2.focus();
      } else {
        this.$refs.otp1.focus();
      }
    },
    otp2: function () {
      if (this.otp2 != null && this.otp2.length == 1) {
        this.$refs.otp3.focus();
      } else {
        this.$refs.otp1.focus();
      }
    },
    otp3: function () {
      if (this.otp3 != null && this.otp3.length == 1) {
        this.$refs.otp4.focus();
      } else {
        this.$refs.otp2.focus();
      }
    },
    otp4: function () {
      if (this.otp4 != null && this.otp4.length == 1) {
        this.$refs.otp5.focus();
      } else {
        this.$refs.otp3.focus();
      }
    },
    otp5: function () {
      if (this.otp5 != null && this.otp5.length == 1) {
        this.$refs.otp6.focus();
      } else {
        this.$refs.otp4.focus();
      }
    },
    otp6: function () {
      if (this.otp6 != null && this.otp6.length == 1) {
        this.$nextTick(() => {
          this.verifyOtp();
        });
      } else {
        this.$refs.otp5.focus();
      }
    },
  },
};
</script>
