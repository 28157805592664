import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
    user: null,
    enums: [],
    token: null,
    notification: [],
    statistics: {},
    notifyCount: 0,
    notifyFilter: {
        PageSize: 5,
        Page: 1,
        SearchTerm: null,
    },
    socket:null,
    localisation: [],
    language:null,
}
export default {
    state,
    getters,
    actions,
    mutations
}