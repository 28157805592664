import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import appointments from "./appointments";
import room from "./room";
import filemanager from "./filemanager";
import messagebox from "./messagebox";
import categories  from "./categories"
import faq from "./faq";
import posts from "./posts";
import supportrequest from "./supportrequest"
import users from "./users";
import policy from "./policy";
import children from  "./children"
import diagnosticcode from "./diagnosticcode"
import paymenthistory from "./paymenthistory"
import transactionhistory from "./transactionhistory"
import interviewrecordconfirmation from "./interviewrecordconfirmation"
import approvalhistory from "./approvalhistory"
import recorddownloadhistory from "./recorddownloadhistory"


Vue.use(Vuex);
export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        appointments,
        room,
        filemanager,
        messagebox,
        faq,
        categories,
        posts,
        supportrequest,
        users,
        policy,
        children,
        diagnosticcode,
        paymenthistory,
        transactionhistory,
        interviewrecordconfirmation,
        approvalhistory,
        recorddownloadhistory
    },
});