export default {
    setUserAppointments(state, data) {
        state.appointments = data
    },
    setPendingAppointments(state, data) {
        state.pendingAppointments = data;
    },
    deletePendingAppointments(state, data) {
        state.pendingAppointments = state.pendingAppointments.filter(item => item.id !== data.id);
    },
    setCurrentAppointment(state, data) {
        state.currentAppointment = data;
    },
    setCurrentAppoinmentMessage(state, data) {
        state.currentMessage = data.reverse();
    },
    addCurrentAppointmentMessage(state, data) {
        state.currentMessage.push(data)
    },
    setCurrentDoctor(state, data) {
        state.currentDoctor = data;
    },
    clearMessage(state) {
        state.currentMessage = [];
    }
}