import { client } from "@/helpers/axiosHelpers";
 import { Message} from "element-ui";

export default{
    async getDiagnosticList(context) {
        var response = await client.post("/DiagnosticCode/List", context.state.filter);
        if (response.data.HasError) {
          Message.error(response.data.Message);
        } else {
          context.commit("setDiagnosticList",response.data.Data.Items) ;
        }
      },
}