import { client } from "@/helpers/axiosHelpers";
import { Message } from "element-ui";

export default {
  async getUsersList(context, payload) {
    var res = await client.post("/User/List", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setUsersList",res.data.Data.Items)
      context.commit("setUsersTotalCount",res.data.Data.TotalCount)
      context.commit("setUsersPageCount",res.data.Data.PageCount)
    }
  },
};
